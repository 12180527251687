import { joinElements } from "lib/react-utils";
import { classNames } from "lib/utils";
import _ from "lodash";
import Link from "next/link";
import React from "react";

export type BreadcrumbItemInfo = { href?: NextTypes.LinkHref; content: React.ReactNode } | React.ReactNode;

interface BreadcrumbsProps {
  items: BreadcrumbItemInfo[] | BreadcrumbItemInfo;
}

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  return (
    <div className="flex items-center gap-x-2">
      {_.isArray(items) ? (
        joinElements(
          items.map((item, i) => <BreadcrumbItem key={i} active={i === items.length - 1} item={item} />),
          <BreadcrumbSeparator />,
        )
      ) : (
        <BreadcrumbItem active item={items} />
      )}
    </div>
  );
};

/**
 * Handles the logic of processing BreadcrumbItemInfo (which can be either a string or an object)
 */
const BreadcrumbItem = ({ item, active }: { item: BreadcrumbItemInfo; active: boolean }) => {
  const itemIsReactNode = React.isValidElement(item) || _.isString(item);
  return (
    <BreadcrumbComponent active={active} href={itemIsReactNode ? undefined : (item as { href?: string }).href}>
      {itemIsReactNode ? item : (item as { content: React.ReactNode }).content}
    </BreadcrumbComponent>
  );
};

interface BreadcrumbComponentProps {
  active: boolean;
  href?: NextTypes.LinkHref;
}

/**
 * Only handles rendering
 */
const BreadcrumbComponent = ({ active, href, children }: React.PropsWithChildren<BreadcrumbComponentProps>) => {
  const Component = active ? "h1" : "div";

  const component = (
    <Component
      className={classNames(
        "select-none",
        active ? "text-oldgray-900" : "text-oldgray-600",
        href && "cursor-pointer hover:text-oldgray-700",
      )}
    >
      {children}
    </Component>
  );

  if (href) {
    return (
      <Link href={href} shallow>
        {component}
      </Link>
    );
  } else {
    return component;
  }
};

const BreadcrumbSeparator = () => {
  return <span className="mx-8 font-bold text-oldgray-600">/</span>;
};
