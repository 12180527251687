import { EntityLabel, FileEntityLabelProps } from "./EntityLabel";
import { VersionPopover } from "./VersionPopover";
import { getFileTypeFromVersionId } from "../utils/versions";
import { File } from "@/types/app/file";
import { useFile } from "@/services/files.service";
import { HoverPopover } from "./shared/HoverPopover";
import * as RadixPopover from "@radix-ui/react-popover";

// You can pass in a full Version object (preferably, saves a fetch) or
// IDs and this component will fetch the Version object for you.
type VersionIdentifierProps =
  | {
      file: File;
    }
  | {
      fileId: string;
      versionId: string;
    };

type EntityLabelWithPopoverProps = (Omit<
  FileEntityLabelProps,
  "fileId" | "versionId" | "entity" | "deployedEnvironments" | "committed" | "fileName" // To control whether `fileName` is displayed, use `displayFile`
> & { environment?: string | null }) &
  VersionIdentifierProps & {
    tooltipContentProps?: React.ComponentProps<typeof RadixPopover.Content>;
    target?: "_blank" | "_self";
    /** Generally don't use this as click should just show the popover - its only needed for dropdowns/filters maybe */
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    displayFile?: boolean;
    side?: RadixPopover.PopoverContentProps["side"];
    align?: RadixPopover.PopoverContentProps["align"];
  };

/**
 * Label with an on-hover popover that displays Version details.
 *
 * This should only be used for File entities that have versions.
 */
export const FileLabelWithPopover = ({
  tooltipContentProps,
  size,
  target = "_blank",
  onClick,
  side,
  align,
  ...props
}: EntityLabelWithPopoverProps) => {
  // As this component allows the user to pass in a full File object or just a versionId,
  // we conditionally fetch within useFile only if versionId was provided (and not the full File object).
  const { file: fetchedFile } = useFile(
    "versionId" in props ? { fileId: props.fileId, versionId: props.versionId } : null,
  );
  const file = "file" in props ? props.file : fetchedFile; // Use passed in File object or fetched File (from ID) based on props.
  const versionId = "versionId" in props ? props.versionId : props.file.version_id;

  const fetchedFileName = fetchedFile?.name;

  const trigger = (
    <EntityLabel
      entity={getFileTypeFromVersionId(versionId)}
      versionId={versionId}
      size={size}
      deployedEnvironments={file?.environments || []}
      committed={file?.status === "committed"}
      fileName={props.displayFile ? file?.name || fetchedFileName : undefined}
      {...props}
    />
  );

  const content = file && <VersionPopover file={file} target={target} />;

  return (
    <HoverPopover
      trigger={trigger}
      content={content}
      tooltipContentProps={tooltipContentProps}
      triggerOnClick={onClick}
      side={side}
      align={align}
    />
  );
};
