import { getTextFromMessage } from "@/lib/messages";
import { ModelConfig } from "@/services/configs.service";
import { ModelEndpoint } from "@/services/playground.service";
import { JsonSchema, LinkedTool, EditorTool } from "@/services/v4.service";
import { Prompt } from "@/types/app/prompt";
import { EditorState, Modifier, SelectionState } from "draft-js";
import _ from "lodash";

// Regex looking for {{variable}} (with up to a single space between the brackets)
// Letters, numbers, and underscores are allowed.
// The matching variable name is available as match[1], while the full matched text
// (i.e. including curly braces) is available as match[0].
// TODO: Disallow variable names that start with a number
// JAB ^ is that required? We're using dicts everywhere I'd hope.
// https://regexr.com/7ou70
// NB: This regex needs to be kept in sync with the one in the backend in interfaces/model.py
export const INPUT_REGEX = /{{[\s]?([a-zA-Z_0-9\.\[\]]*)[\s]?}}/g;

// Regex looking for {{tool_name("arg1", "arg2")}}
// Matches the inner args as match[2] which will then need to be parsed (split + trim)
// https://regexr.com/7oo32
// {{ serp_api(query) }} -> serp_api(query), serp_api, query
// {{serp_api("my string", my_var)}} -> serp_api("my string"), serp_api, `"my string", my_var`
// NB: This regex needs to be kept in sync with the one in the backend in interfaces/model.py
// This regex is slightly different in that it has different capture groups
export const TOOL_REGEX = /{{[\s]?([a-zA-Z_0-9-]+)\(([\"\w\s,-]*)\)[\s]?}}/g;

// TODO: Remove in favour of extractInputsFromPromptVersion` when migration to v5 is complete
export const extractInputsFromModelConfig = (modelConfig: ModelConfig): string[] => {
  // TODO(v5): Update.
  const endpoint = modelConfig.endpoint ?? ModelEndpoint.chat;
  return extractInputsFromPromptVersion({
    endpoint,
    template:
      endpoint === ModelEndpoint.chat ? (modelConfig.chat_template ?? null) : (modelConfig.prompt_template ?? null),
  });
};

export const extractInputsFromPromptVersion = (promptVersion: Pick<Prompt, "endpoint" | "template">): string[] => {
  switch (promptVersion.endpoint) {
    case ModelEndpoint.chat:
      if (!Array.isArray(promptVersion.template)) {
        console.error("Chat template is not an array", promptVersion.template);
        return [];
      }
      return _.uniq(
        (promptVersion.template || []).flatMap((message) =>
          extractInputsFromPrompt(getTextFromMessage(message.content)),
        ),
      );
    case ModelEndpoint.complete:
      if (typeof promptVersion.template !== "string") {
        console.error("Prompt template is not a string", promptVersion.template);
        return [];
      }
      return extractInputsFromPrompt(promptVersion.template || "");
    default:
      throw new Error("Unknown model endpoint");
  }
};

export const extractInputsFromPrompt = (prompt: string): string[] => {
  // Extract the list of input variable names from the prompt
  // Extended with the inputs from any tools too.
  // > extractInputsFromPrompt("{{action}} a poem by {{wiki(author)}} on {{topic}}:")
  // [ 'action', 'author', 'topic' ]
  const inputIndices = extractInputIndicesFromPrompt(prompt);
  const inputs = inputIndices.map((x) => x.name);
  const toolsIndices = extractToolIndicesFromPrompt(prompt);
  const toolInputs = toolsIndices.map((x) => x.args.filter((y) => y.type === "variable").map((y) => y.arg)).flat();
  // console.log({ inputs, toolInputs });
  return _.uniq(inputs.concat(toolInputs));
};

export const extractInputIndicesFromPrompt = (
  prompt: string,
): { start: number; end: number; name: string; type: "input" }[] => {
  // > extractInputIndicesFromPrompt("{{action}} a poem by {{author}} on {{topic}}:")
  // [
  //   { start: 0, end: 10, name: 'action' },
  //   { start: 21, end: 31, name: 'author' },
  //   { start: 35, end: 44, name: 'topic' }
  // ]
  if (!prompt) {
    return [];
  }
  return [...prompt.matchAll(INPUT_REGEX)].map((x) => {
    if (x.index === undefined) {
      throw new Error(`matchAll returned undefined index for x ${x}`);
    }
    return {
      start: x.index,
      end: x.index + x[0].length,
      name: x[1],
      type: "input",
    };
  });
};

interface ToolIndexArg {
  start: number;
  end: number;
  arg: string;
  type: "literal" | "variable";
}

interface ToolIndex {
  start: number;
  end: number;
  call: string;
  name: string;
  args: ToolIndexArg[];
  type: "tool";
}

export const extractToolIndicesFromPrompt = (prompt: string): ToolIndex[] => {
  if (!prompt) {
    return [];
  }
  return [...prompt.matchAll(TOOL_REGEX)].map((x) => {
    if (x.index === undefined) {
      throw new Error(`matchAll returned undefined index for x ${x}`);
    }

    const openParenthesisIndex = x[1].length + 3; // 3 for the opening {{ and (
    let match;
    let args: ToolIndexArg[] = [];
    // I got this from ChatGPT :)
    const regex = /(?<name>"[^"]+"|\w+)(\s*,)?/g;
    while ((match = regex.exec(x[2]))) {
      if (match.groups === undefined) {
        continue;
      }
      if (match.index === undefined) {
        throw new Error(`matchAll returned undefined index for match ${match}`);
      }
      // If starts with a number or a double/single/back quote, it's a literal
      // otherwise it's a variable
      const type = match.groups.name.match(/^[0-9"']/) ? "literal" : "variable";
      args.push({
        start: openParenthesisIndex + match.index,
        end: openParenthesisIndex + match.index + match[0].length,
        arg: match.groups.name,
        type: type,
      });
    }
    return { start: x.index, end: x.index + x[0].length, call: x[0], name: x[1], args: args, type: "tool" };
  });
};

export const extractToolsFromPrompt = (prompt: string): { name: string; call: string }[] => {
  const toolsIndices = extractToolIndicesFromPrompt(prompt);
  const toolNamesAndCalls = toolsIndices.map((x) => ({ name: x.name, call: x.call }));
  // Unique by call
  return _.uniqBy(toolNamesAndCalls, "call");
};

export const addTextAtCursor = (text: string, editorState: EditorState): EditorState => {
  const currentContent = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();

  let newContent = Modifier.replaceText(currentContent, currentSelection, text);

  const textToInsertSelection = currentSelection.set(
    "focusOffset",
    currentSelection.getFocusOffset() + text.length,
  ) as SelectionState;

  const inlineStyles = editorState.getCurrentInlineStyle();
  inlineStyles.forEach(
    (inlineStyle) => (newContent = Modifier.applyInlineStyle(newContent, textToInsertSelection, inlineStyle || "")),
  );

  let newState = EditorState.push(editorState, newContent, "insert-characters");
  newState = EditorState.forceSelection(
    newState,
    textToInsertSelection.set("anchorOffset", textToInsertSelection.getAnchorOffset() + text.length) as any,
  );

  return newState;
};

export const deleteTextAtCursor = (characters: number, editorState: EditorState): EditorState => {
  const currentContent = editorState.getCurrentContent();
  const currentSelection = editorState.getSelection();

  let newContent = Modifier.removeRange(
    currentContent,
    currentSelection.merge({
      anchorOffset: currentSelection.getAnchorOffset() - characters,
      focusOffset: currentSelection.getAnchorOffset(),
    }),
    "backward",
  );

  let newState = EditorState.push(editorState, newContent, "remove-range");

  return newState;
};

export const DEFAULT_TOOL_PARAMETER_SCHEMA = {
  type: "object",
  properties: {},
  required: [],
} as JsonSchema;

// When comparing tool configs to see if the tools are equivalent we want to only change the name, description, and parameter_schema
export const areToolsEqual = (tool1: EditorTool | LinkedTool, tool2: EditorTool | LinkedTool): boolean => {
  // If either tool is a linked tool, don't compare. We only care about inline tools.
  if ("id" in tool1 || "id" in tool2) {
    return false;
  }

  return (
    tool1.name === tool2.name &&
    tool1.description === tool2.description &&
    _.isEqual(tool1.parameters, tool2.parameters)
  );
};
