import { ChatMessage, ChatMessageContent, ImageChatContent, TextChatContent } from "@/services/playground.service";
import imageCompression, { Options } from "browser-image-compression";
import { useCallback } from "react";

/**
 * Get the first text message content item from a list of content items
 */
const getTextMessageContentItem = (contentItems: ChatMessageContent[]): string | undefined => {
  const textMessageContentItem = contentItems.find((item) => item.type === "text") as TextChatContent | undefined;
  return textMessageContentItem?.text;
};

/**
 * Get the text sent by the user in a message's content.
 * Just a convenience function to get a string to display
 */
export const getTextFromMessage = (messageContent: ChatMessage["content"]): string => {
  if (Array.isArray(messageContent)) {
    return getTextMessageContentItem(messageContent) ?? "";
  }
  if (messageContent) {
    if (typeof messageContent === 'string' && messageContent.trim().startsWith('{')) {
      try {
        const parsed = JSON.parse(messageContent);
        return JSON.stringify(parsed, null, 2);
      } catch (e) {
        // If parsing fails, return the original string
        return messageContent;
      }
    }
    return messageContent;
  }
  return "";
};

/**
 * Get the images from a message content.
 * Returns an empty array if there are no images.
 */
export const getMessageImageContent = (messageContent: ChatMessage["content"]): ImageChatContent[] => {
  if (Array.isArray(messageContent)) {
    return messageContent.filter((contentItem) => chatContentIsImage(contentItem)) as ImageChatContent[];
  }
  return [];
};
/** Interpret the 'output' string of a log as an assistant message
 *
 * All logs up until 2023-11-27 did not have 'output_message' field, they just has 'output', which was
 * used even if the response was a tool call. Given that we haven't migrated old logs, we need to
 * still support getting the "output_message" from the log by parsing the "output" field.
 * At some point, when we no longer care about rendering the old logs with the actual output message,
 * we can remove this and just use the actual output_message field.
 */
export const parseLogOutputAsMessage = (logOutput: string): ChatMessage => {
  try {
    const jsonContent = JSON.parse(logOutput);
    if (jsonContent.hasOwnProperty("name") && jsonContent.hasOwnProperty("arguments")) {
      return { role: "assistant", name: jsonContent?.name || null, content: jsonContent?.arguments || "" };
    } else {
      return { role: "assistant", name: null, content: logOutput };
    }
  } catch (e) {
    return { role: "assistant", name: null, content: logOutput };
  }
};

const chatContentIsImage = (content: ChatMessageContent): content is ImageChatContent => {
  return content.type === "image_url";
};

export const isValidHttpUrl = (url: string): boolean => {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (err) {
    return false;
  }
};

export const isValidDataUrl = (url: string): boolean => {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === "data:";
  } catch (err) {
    return false;
  }
};

/**
 * Compress image to one appropriate for OpenAI.
 *
 * Attempts to compress image to a smaller file size so the resulting data URL is shorter,
 * and resize if necessary.
 * Resizing is done, if necessary, so the long side is <= 2048px and the short side is <= 768px.
 *
 * Note that Safari does not support webp, and this seems to default to jpeg instead.
 * https://caniuse.com/webp
 */
export const compressImageForOpenAi = async (image: File): Promise<File> => {
  const MAX_LONG_SIDE = 2048;
  const MAX_SHORT_SIDE = 768;
  const MAX_SIZE_MB = 0.3;

  // Create an image element to get the original image dimensions
  // TODO: Consider blueimp-load-image to get the image dimensions without loading the whole image.
  const imageElement = document.createElement("img");
  const imageUrl = URL.createObjectURL(image);
  imageElement.src = imageUrl;

  await new Promise((resolve) => {
    imageElement.onload = resolve;
  });

  const imageWidth = imageElement.width;
  const imageHeight = imageElement.height;

  if (
    Math.max(imageWidth, imageHeight) < MAX_LONG_SIDE &&
    Math.min(imageWidth, imageHeight) < MAX_SHORT_SIDE &&
    image.size < MAX_SIZE_MB * 1024 * 1024
  ) {
    // Image is already small enough
    return image;
  }

  // Calculate the aspect ratio of the image
  let options: Options = {
    maxSizeMB: MAX_SIZE_MB,
    useWebWorker: true,
    fileType: "image/webp",
    maxIteration: 20,
  };

  let resizeFactor = 1;
  let maxWidthOrHeight = Math.max(imageWidth, imageHeight);

  // Resize the image if the long side is too long
  if (imageWidth > MAX_LONG_SIDE || imageHeight > MAX_LONG_SIDE) {
    const longSideResizeFactor = MAX_LONG_SIDE / Math.max(imageWidth, imageHeight);
    if (longSideResizeFactor < resizeFactor) {
      resizeFactor = longSideResizeFactor;
      maxWidthOrHeight = Math.max(imageWidth, imageHeight) * longSideResizeFactor;
      console.log(`Resizing by long side to ${maxWidthOrHeight}px`);
    }
  }
  if (imageWidth > MAX_SHORT_SIDE || imageHeight > MAX_SHORT_SIDE) {
    const shortSideResizeFactor = MAX_SHORT_SIDE / Math.min(imageWidth, imageHeight);
    if (shortSideResizeFactor < resizeFactor) {
      resizeFactor = shortSideResizeFactor;
      maxWidthOrHeight = Math.max(imageWidth, imageHeight) * shortSideResizeFactor;
      console.log(`Resizing by short side to ${maxWidthOrHeight}px`);
    }
  }
  if (resizeFactor < 1) {
    options = {
      ...options,
      maxWidthOrHeight,
    };
  }

  // Clean up the image element and revoke the object URL
  imageElement.src = "";
  URL.revokeObjectURL(imageUrl);

  // Compress and resize the image
  const compressedImage = await imageCompression(image, options);

  return compressedImage;
};

export const fileToDataURL = (file: File): Promise<string> => {
  return imageCompression.getDataUrlFromFile(file);
  // The following code is an alternative to `imageCompression.getDataUrlFromFile`
  // Kept around in case we want to remove the library dependency.
  // return new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     if (typeof reader.result === "string") {
  //       resolve(reader.result);
  //     } else {
  //       reject("Unexpected result type");
  //     }
  //   };
  //   reader.onerror = () => reject(reader.error);
  //   reader.readAsDataURL(file);
  // });
};

export const IMAGE_ACCEPT = {
  // https://platform.openai.com/docs/guides/vision/what-type-of-files-can-i-upload
  // png, jpeg (jpeg or jpg), webp, gif (non-animated)
  "image/jpeg": [],
  "image/png": [],
  "image/webp": [],
  "image/gif": [],
};

/**
 * Returns a callback that can be used to handle files dropped onto a dropzone.
 * Turns the files into data URLs and calls the provided callback with the data URLs.
 */
export const useFilesOnDrop = (onDrop: (dataUrls: string[]) => void) => {
  return useCallback(
    async (files: File[]) => {
      const compressedFiles = await Promise.all(files.map(compressImageForOpenAi));
      const dataUrls = await Promise.all(compressedFiles.map(fileToDataURL));
      onDrop(dataUrls);
    },
    [onDrop],
  );
};
