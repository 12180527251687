import IconButton from "@components/library/IconButton";
import SplitTag from "@components/library/SplitTag";
import { XIcon } from "@heroicons/react/outline";
import { classNames } from "lib/utils";
import React, { forwardRef, useCallback, useImperativeHandle } from "react";

export interface StopWordsInputProps {
  value: string[] | string;
  setValue: (value: string[]) => void;
}

export const StopTag = ({ stop, onClick }: { stop: string; onClick?: () => void }) => {
  return (
    <div className="inline-flex shrink items-center justify-between rounded-sm bg-oldgray-300 ">
      <span className="px-4 py-2 text-11-14">{lineBreakToEmoji(stop)}</span>
      {onClick && <IconButton className="-my-1 opacity-40 hover:opacity-90" Icon={XIcon} size={20} onClick={onClick} />}
    </div>
  );
};

const lineBreakToEmoji = (sequence: string) => {
  // Replace \n newlines with ⮐ symbol
  return sequence.replace(/\n/g, "⏎");
};

export const StopWordsSplitTag = ({
  interactive,
  value,
  setValue,
}: {
  value: string[] | string;
  interactive?: boolean;
  setValue?: (value: string[]) => void;
}) => {
  if (typeof value === "string") {
    value = [value];
  }
  return (
    <SplitTag
      interactive={interactive}
      tagLabel="Stop"
      tagValue={
        value?.length > 0 ? (
          <div className="inline-flex w-full flex-wrap gap-4">
            {value.map((stopSequence, index) => (
              // TODO: Fix and avoid repeating from StopWordsInput.tsx
              <StopTag
                key={index}
                stop={stopSequence}
                onClick={
                  setValue
                    ? () => {
                        // Remove the sequence from the array
                        const newValue = [...value];
                        newValue.splice(index, 1);
                        setValue(newValue);
                      }
                    : undefined
                }
              />
            ))}
          </div>
        ) : (
          " None"
        )
      }
    />
  );
};

// Adding a ref so the saveValue function can be called from the parent component
export const StopWordsInput = forwardRef(function StopWordsInputComponent(
  { value, setValue }: StopWordsInputProps,
  ref,
) {
  if (typeof value === "string") {
    value = [value];
  }
  const [backspaceCount, setBackspaceCount] = React.useState<number>(0); // Have to hit backspace twice to delete a sequence.
  const [draft, setDraft] = React.useState<string>("");

  // Extending the ref with the saveValue function
  useImperativeHandle(ref, () => ({
    saveValue: () => {
      saveValue();
    },
  }));

  const emojiToLineBreak = (sequence: string) => {
    // Replace ⮐ symbol with \n newlines
    return sequence.replace(/⮐/g, "\n");
  };

  // Add a new stop sequence to the array if the user presses tab, or enter
  // after other characters, iff the sequences is unique.
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      saveValue();
    } else if (e.key === "Enter") {
      e.preventDefault();
      setDraft(draft + "⮐");
    } else if (e.key === "Backspace" && draft.length === 0 && value?.length > 0) {
      e.preventDefault();
      setBackspaceCount(backspaceCount + 1);
      if (backspaceCount >= 1) {
        setValue(value.slice(0, -1));
        setBackspaceCount(0);
      }
    }
  };
  const saveValue = useCallback(() => {
    if (draft.length > 0 && !value.includes(emojiToLineBreak(draft)) && value.length < 4) {
      setValue([...value, emojiToLineBreak(draft)]);
      setDraft("");
    }
  }, [draft, value, setValue, setDraft]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDraft(e.target.value);
  };
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div>
      <div
        className="group relative grow-0 cursor-text"
        onClick={
          // Focus on the input
          () => inputRef.current?.focus()
        }
      >
        <div className="flex min-h-[34px] w-full grow-0 items-center justify-between rounded-ms border border-oldgray-500 bg-white p-4">
          <div className="inline-flex w-full flex-wrap gap-4">
            {value.map((stopSequence, index) => (
              <StopTag
                key={index}
                stop={stopSequence}
                onClick={() => {
                  // Remove the sequence from the array
                  const newValue = [...value];
                  newValue.splice(index, 1);
                  setValue(newValue);
                }}
              />
            ))}
            {/* TODO: want to make this be on the same line as the sequences where possible */}
            <input
              ref={inputRef}
              className={classNames(
                "w-32 shrink grow bg-white pb-[3px] pl-4 pt-4 text-11-14 focus:h-[inherit] focus:outline-none ",
              )}
              value={draft}
              onKeyDown={handleKeyDown}
              onInput={handleChange}
              onBlur={saveValue}
            />
          </div>
          {value.length > 0 && (
            <IconButton
              className="mx-2"
              disabled={value.length === 0}
              Icon={XIcon}
              onClick={() => {
                setValue([]);
              }}
            />
          )}
        </div>

        <div className="mt-8 flex gap-8 text-12-14">
          {draft.length > 0 ? (
            value.length < 4 ? (
              <span className="text-oldgray-600">Press TAB to set</span>
            ) : (
              value.length === 4 && <span className="text-oldgray-600">Maximum of 4</span>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
});
