import { classNames } from "@/lib/utils";
import { File } from "@/types/app/file";
import { ParametersDetailsSmall } from "@components/llama/Configs/PromptDetails/ParametersDetails";
import { InputLabel } from "@components/molecules/FormikInput";
import _ from "lodash";
import React from "react";
import { EntityPopover } from "./shared/EntityPopover";
import { Tag } from "../Tag";
import { titleFromSnakeOrCamel } from "@/lib/string";
import { useAppHref } from "@/lib/path-utils";

interface Props {
  file: File;
  target?: "_blank" | "_self";
  noRoot?: boolean; // If true, the root element will not be rendered. Used when this component isn't actually a popover, but the shared underlying content. E.g. FilePicker's Version preview.
}

/**
 * This component is only the card. For the Label and Tooltip, use `FileLabelWithPopover`.
 */
export const VersionPopover = ({ file, target = "_blank", noRoot }: Props) => {
  const isCommitted = file.status === "committed";
  const user = isCommitted ? file.committed_by : file.created_by;

  const href =
    useAppHref({
      id: file.id,
      page: "dashboard",
      type: "file",
    }) + `?id=${file.version_id}`;

  const versionType = file.type;

  const Root = noRoot ? React.Fragment : EntityPopover.Root;
  return (
    <Root>
      <EntityPopover.Header.Root>
        <EntityPopover.Header.Title>
          <EntityPopover.Header.Icon type={versionType} />
          <EntityPopover.Header.File file={file.name} />
        </EntityPopover.Header.Title>
        <EntityPopover.Header.VersionLabel
          entity={versionType}
          versionId={file.version_id}
          deployedEnvironments={file.environments}
          committed={isCommitted}
        />
        <EntityPopover.Header.LinkButton
          tooltip={`View ${_.capitalize(versionType)} Version`}
          href={href}
          target={target}
        />
      </EntityPopover.Header.Root>

      {/* File contents part */}
      {/* TODO: I think pull this out to make separate popover components for each file type. This
      is a poorly thought through hack to get the instructions to display somewhere */}
      {file.type === "evaluator" && (
        <>
          <div className="flex flex-wrap gap-8 text-12-14 font-normal text-text-base-2">
            <Tag> {titleFromSnakeOrCamel(file.spec.arguments_type)}</Tag>
            <Tag> {titleFromSnakeOrCamel(file.spec.return_type)}</Tag>
            <Tag> {titleFromSnakeOrCamel(file.spec.evaluator_type)}</Tag>
          </div>

          {"instructions" in file.spec && file.spec.instructions && (
            <>
              <InputLabel name="instructions" label="Instructions" />

              <textarea
                key={file.version_id}
                className={classNames(
                  "-mt-8 whitespace-pre-wrap rounded",
                  "border border-stroke-base-3 bg-background-base-1 px-8 py-12 text-12-14 font-normal text-text-base-2",
                )}
                readOnly
                // Can't get this to work, it keeps autofocusing and having big thick blue border...
                autoFocus={false}
              >
                {file.spec.instructions}
              </textarea>
            </>
          )}
        </>
      )}

      {file.type === "dataset" && (
        <div className="text-12-14 font-normal text-text-base-2">{file.datapoints_count} datapoints</div>
      )}

      {file.type === "prompt" && (
        <>
          <ParametersDetailsSmall {...file} />
        </>
      )}

      {isCommitted && (
        <EntityPopover.CommitMessage size="sm" hasOwner={file.committed_by !== null}>
          {file.commit_message}
        </EntityPopover.CommitMessage>
      )}
      {user && (
        <EntityPopover.Author.Root>
          <EntityPopover.Author.Avatar user={user} />
          <EntityPopover.Author.Text>
            <EntityPopover.Author.Name>{user.full_name}</EntityPopover.Author.Name>
            <EntityPopover.Author.ActionAndTimestamp
              action={!isCommitted ? "created" : user === null ? "Committed" : "committed"}
              timestamp={isCommitted && file.committed_at ? file.committed_at : file.created_at}
            />
          </EntityPopover.Author.Text>
        </EntityPopover.Author.Root>
      )}
    </Root>
  );
};
