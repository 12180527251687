import { cn } from "@/lib/utils";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import IconButton from "./IconButton";
import { classNames } from "./utils/classNames";
import _ from "lodash";

export const Tag = forwardRef(
  (
    props: React.PropsWithChildren<{
      color?: "cyan" | "yellow" | "green" | "red" | "purple" | "navy" | "blue" | "orange" | "pink" | "gray";
      size?: 18 | 20 | 24;
      IconLeft?: (props: React.ComponentProps<"svg">) => JSX.Element;
      IconRight?: (props: React.ComponentProps<"svg">) => JSX.Element;
      interactive?: boolean;
      onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
      // Assuming that the only icon on the right is a close button
      onDismiss?: () => void;
      className?: string;
      style?: React.CSSProperties;
    }>,
    ref: React.ForwardedRef<HTMLSpanElement>,
  ) => {
    const {
      color: propsColor,
      size = 24,
      IconLeft,
      IconRight,
      interactive,
      onClick,
      onDismiss,
      className,
      children,
      ...rest
    } = props;
    const color = propsColor || "gray";
    let colors = "";
    switch (color) {
      case "cyan":
        colors = cn("bg-oldcyan-200 text-oldcyan-800", interactive && "hover:bg-oldcyan-300");
        break;
      case "yellow":
        colors = cn("bg-oldyellow-200 text-oldyellow-800", interactive && "hover:bg-oldyellow-300");
        break;
      case "green":
        colors = cn("bg-oldgreen-200 text-oldgreen-900", interactive && "hover:bg-oldgreen-300");
        break;
      case "red":
        colors = cn("bg-oldred-200 text-oldred-800", interactive && "hover:bg-oldred-300");
        break;
      case "purple":
        colors = cn("bg-oldpurple-200 text-oldpurple-800", interactive && "hover:bg-oldpurple-300");
        break;
      case "navy":
        colors = cn("bg-oldnavy-200 text-oldnavy-800", interactive && "hover:bg-oldnavy-300");
        break;
      case "blue":
        colors = cn("bg-oldblue-200 text-oldblue-800", interactive && "hover:bg-oldblue-300");
        break;
      case "orange":
        colors = cn("bg-oldorange-200 text-oldorange-800", interactive && "hover:bg-oldorange-300");
        break;
      case "pink":
        colors = cn("bg-oldpink-200 text-oldpink-800", interactive && "hover:bg-oldpink-300");
        break;
      case "gray":
      default:
        colors = cn("bg-background-base-3 text-text-base-1", interactive && "hover:bg-background-base-325");
        break;
    }

    const sizeClass =
      size === 18
        ? "h-[18px] px-0 py-4 text-10-10"
        : size === 20
          ? "h-20 py-2 px-6 text-11-16"
          : "h-24 py-4 px-8 text-11-16"; // 24 is the default

    return (
      <span
        className={classNames(
          sizeClass,
          "inline-flex items-center justify-between gap-16 truncate rounded-ms px-8",
          colors,
          className,
        )}
        onClick={onClick}
        ref={ref}
        // Spread props to allow this to work as a Radix UI trigger
        {...rest}
      >
        <div className="inline-flex items-start gap-4 truncate">
          {IconLeft && <IconLeft className="my-2 h-12 w-12 shrink-0" />}
          {children}
          {IconRight && <IconRight className="my-2 h-12 w-12 shrink-0" />}
        </div>
        {onDismiss && (
          <XIcon
            className="h-16 w-16 cursor-pointer opacity-50 hover:opacity-100 "
            onClick={() => onDismiss && onDismiss()}
          />
        )}
      </span>
    );
  },
);

Tag.displayName = "Tag";

export const TagEditable = ({
  color = "gray",
  className,
  onEdit,
  notEditable,
  children,
}: {
  color?: "cyan" | "yellow" | "green" | "red" | "purple" | "navy" | "orange" | "pink" | "gray";
  onDismiss?: () => void;
  className?: string;
  notEditable?: boolean;
  onEdit?: (value: string) => void;
  children: string;
}): JSX.Element => {
  const [value, setValue] = useState(children);
  const [width, setWidth] = useState(100);
  const [hasFocus, setHasFocus] = useState(false);
  const span = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!span.current) return;
    setWidth(span.current.offsetWidth);
  }, [value, children]);

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!notEditable) {
      setValue(event.target.value);
    }
  };

  let colors = "";
  switch (color) {
    case "cyan":
      colors = "bg-oldcyan-200 text-oldcyan-800 hover:bg-oldcyan-300";
      break;
    case "yellow":
      colors = "bg-oldyellow-200 text-oldyellow-800 hover:bg-oldyellow-300";
      break;
    case "green":
      colors = "bg-oldgreen-200 text-oldgreen-800 hover:bg-oldgreen-300";
      break;
    case "red":
      colors = "bg-oldred-200 text-oldred-800 hover:bg-oldred-300";
      break;
    case "purple":
      colors = "bg-oldpurple-200 text-oldpurple-800 hover:bg-oldpurple-300";
      break;
    case "navy":
      colors = "bg-oldnavy-200 text-oldnavy-800 hover:bg-oldnavy-300";
      break;
    case "orange":
      colors = "bg-oldorange-200 text-oldorange-800 hover:bg-oldorange-300";
      break;
    case "pink":
      colors = "bg-oldpink-200 text-oldpink-800 hover:bg-oldpink-300";
      break;
    case "gray":
    default:
      colors = "bg-oldgray-200 text-oldgray-700 hover:bg-oldgray-300";
      break;
  }

  return (
    <>
      <span
        ref={span}
        className={classNames(
          "absolute -z-10 opacity-0",
          "h-24 min-w-0 justify-between gap-16 truncate rounded px-8 py-4 text-11-16",
          colors,
        )}
      >
        {value}
      </span>
      <input
        className={classNames(
          "inline-flex h-24 justify-between gap-16 truncate rounded px-8 py-4 text-11-16",
          colors,
          className,
        )}
        onFocus={(e) => {
          if (!notEditable) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        value={value}
        onChange={changeHandler}
        onKeyDown={(evt) => {
          if (evt.key === "Enter") {
            onEdit && onEdit(value);
            (evt.target as HTMLInputElement).blur();
          } else if (evt.key === "Escape") {
            setValue(children);
            evt.stopPropagation();
            evt.preventDefault();
          }
        }}
        // More width to stop jitter when typing, and some extra to stop the text being truncated
        style={{ width: width + (hasFocus ? 16 : 3), minWidth: "40px", maxWidth: "300px" }}
      />
      {value !== children && (
        <div className="-mx-4 flex-none">
          <IconButton
            onClick={() => {
              onEdit && onEdit(value);
              setHasFocus(false);
            }}
            Icon={CheckIcon}
            size={20}
          />
          <IconButton
            onClick={() => {
              setValue(children);
              setHasFocus(false);
            }}
            Icon={XIcon}
            size={20}
          />
        </div>
      )}
    </>
  );
};
