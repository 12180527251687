import { classNames } from "@/lib/utils";
import { UnstyledButtonBase, UnstyledButtonBaseProps } from "../library/Button";
import { forwardRef } from "react";
import { CaretDown } from "../library/Icons";
import { TagSize } from "../library/v2/Tag";

interface MessageRoleTagProps extends UnstyledButtonBaseProps {
  interactive?: boolean;
  active?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const MessageRoleTag = forwardRef<HTMLButtonElement, MessageRoleTagProps>(
  ({ children, interactive, active, className, ...props }, ref) => (
    <UnstyledButtonBase
      disabled={!interactive}
      ref={ref}
      {...props}
      className={classNames(
        "flex items-center capitalize",
        "bg-background-base-3 text-text-base-1",
        "gap-4  font-medium",
        interactive ? "select-none hover:bg-gray-150 focus:outline-none  active:outline-none" : "",
        "h-20 gap-4 rounded px-6 py-4 text-12-12",
      )}
    >
      {children}
      {interactive && (
        <CaretDown className={classNames("-mr-2 h-12 w-12 text-text-base-2", active ? "rotate-180" : "")} />
      )}
    </UnstyledButtonBase>
  ),
);
MessageRoleTag.displayName = "MessageRoleTag";
