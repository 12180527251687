import { Environment } from "@/types/app/environment";
import { Tag } from "./Tag";

interface OwnProps {
  environment: Pick<Environment, "name">;
}

type Props = OwnProps & Pick<React.ComponentProps<typeof Tag>, "size">;

export const EnvironmentTag = ({ environment, ...props }: Props) => {
  return (
    <Tag color="green" {...props} className="w-min">
      {environment.name}
    </Tag>
  );
};
