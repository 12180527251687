import { Tooltip } from "@components/library/Tooltip";
import dayjs, { Dayjs } from "dayjs";
import { capitalize } from "lodash";

export const RelativeTimestamp = ({ timestamp }: { timestamp: Dayjs }) => {
  if (!timestamp) return null;

  // LLL = August 16, 2045 (en) or 16 August 2045 (en-gb) https://day.js.org/docs/en/display/format
  return (
    <Tooltip content={timestamp.format("LLL")}>
      <span>{capitalize(timestamp.fromNow())}</span>
    </Tooltip>
  );
};

export const AbsoluteTimestamp = ({ timestamp }: { timestamp: Dayjs }) => {
  if (!timestamp) return null;

  return (
    <Tooltip content={timestamp.format("LLL")}>
      {/*  ll = Aug 16, 2045 (en) or 16 Aug 2045 (en-gb) https://day.js.org/docs/en/display/format */}
      <span>{timestamp.format("ll")}</span>
    </Tooltip>
  );
};

/**
 * Displays a timestamp that is relative if it is within a certain window of the current date.
 * @param timestamp - The timestamp to display
 * @param windowInDays - The number of days (each side  ) to consider for the relative timestamp
 * @returns A timestamp that is relative if it is within a certain window of the current date
 */
export const DynamicTimestamp = ({ timestamp, windowInDays = 7 }: { timestamp: Dayjs; windowInDays?: number }) => {
  if (!timestamp) return null;

  const now = dayjs();
  const isWithinWindow = now.diff(timestamp, "day") <= windowInDays;

  return isWithinWindow ? <RelativeTimestamp timestamp={timestamp} /> : <AbsoluteTimestamp timestamp={timestamp} />;
};
