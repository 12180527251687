import { PageSectionHeader } from "@components/library/PageSectionHeader";
import { DescriptionDetails, DescriptionList, DescriptionTerm } from "../../DescriptionList";
import { InfoTooltip } from "@components/molecules/InfoTooltip";
import CodePreview from "../../Code/CodePreview";
import { isEmpty, isObject } from "lodash";
import SplitTag from "@components/library/SplitTag";
import { titleFromSnakeOrCamel } from "@/lib/string";
import { StopWordsSplitTag } from "@components/playground/Parameters/StopWordsInput";
import { ProviderLogo } from "@components/atoms/ProviderLogo";
import { PromptKernelRequest } from "@/types/app/prompt";

type ParametersDetailsProps = Omit<PromptKernelRequest, "linked_tools" | "tools">;

export const ParametersDetails = (props: ParametersDetailsProps) => {
  return (
    // Min h of roughly three lines on param section to stop layout shift as navigating between prompts
    <div className="min-h-[128px]">
      <PageSectionHeader title="Parameters" size="sm" />
      <div className=" inline-flex flex-wrap items-start gap-x-8 gap-y-8">
        {props.provider && (
          <SplitTag
            tagLabel="Model"
            tagValue={
              <>
                <span className="flex items-center gap-4">
                  <ProviderLogo name={props.provider} className={"h-14 w-14 opacity-70"} /> {props.model}
                </span>
              </>
            }
          />
        )}
        {/* Always show these */}
        {(["temperature"] as const).map((key) => {
          if (key in props) {
            let value = props[key];
            return (
              <SplitTag
                key={key}
                tagLabel={titleFromSnakeOrCamel(key)}
                tagValue={isObject(value) ? JSON.stringify(value) : value}
              />
            );
          }
          return null;
        })}
        {/* Only show if non-default value */}
        {(
          [
            ["max_tokens", -1],
            ["top_p", 1],
            ["frequency_penalty", 0],
            ["presence_penalty", 0],
          ] as const
        ).map(([key, defaultValue]) => {
          if (key in props) {
            const value = props[key as keyof ParametersDetailsProps];
            if (value === null || value === undefined || value === defaultValue) return null;
            return (
              <SplitTag
                key={key}
                tagLabel={titleFromSnakeOrCamel(key)}
                tagValue={isObject(value) ? JSON.stringify(value) : value}
              />
            );
          }
          return null;
        })}

        {props.stop && <StopWordsSplitTag value={props.stop} />}

        {/* Only show if has defined value */}
        {(["seed"] as const).map((key) => {
          if (key in props) {
            let value = props[key];
            if (value === null || value === undefined) return null;
            return (
              <SplitTag
                key={key}
                tagLabel={titleFromSnakeOrCamel(key)}
                tagValue={isObject(value) ? JSON.stringify(value) : value}
              />
            );
          }
          return null;
        })}

        {/* Special handling for 'response_format' which is an object but so far only has one key (json mode) so we can just show the value */}
        {props.response_format && (
          <SplitTag tagLabel={"JSON Mode"} tagValue={props.response_format.type === "json_object" ? "On" : "Off"} />
        )}
      </div>
      {!isEmpty(props.other) && (
        <DescriptionList className="my-16">
          <DescriptionTerm className="flex gap-6">
            Other <InfoTooltip content="Additional provider parameters that affect the output" />
          </DescriptionTerm>
          <DescriptionDetails>
            <CodePreview language="json" wrapLongLines className=" text-12-16">
              {JSON.stringify(props.other, null, 2)}
            </CodePreview>
          </DescriptionDetails>
        </DescriptionList>
      )}
    </div>
  );
};

/**  Small version of the parameters details for use in the version popover */
export const ParametersDetailsSmall = (props: ParametersDetailsProps) => {
  return (
    <div className="">
      <div className=" inline-flex flex-wrap items-start gap-x-8 gap-y-8">
        {props.provider && (
          <SplitTag
            tagLabel="Model"
            tagValue={
              <>
                <span className="flex items-center gap-4">
                  <ProviderLogo name={props.provider} className={"h-14 w-14 opacity-70"} /> {props.model}
                </span>
              </>
            }
          />
        )}

        {/* Only show if non-default value */}

        {(
          [
            ["temperature", 0],
            ["max_tokens", -1],
            ["top_p", 1],
            ["frequency_penalty", 0],
            ["presence_penalty", 0],
          ] as const
        ).map(([key, defaultValue]) => {
          if (key in props) {
            const value = props[key as keyof ParametersDetailsProps];
            if (value === null || value === undefined || value === defaultValue) return null;
            return (
              <SplitTag
                key={key}
                tagLabel={titleFromSnakeOrCamel(key)}
                tagValue={isObject(value) ? JSON.stringify(value) : value}
              />
            );
          }
          return null;
        })}

        {/* Only show if has defined value */}
        {(["seed"] as const).map((key) => {
          if (key in props) {
            let value = props[key];
            if (value === null || value === undefined) return null;
            return (
              <SplitTag
                key={key}
                tagLabel={titleFromSnakeOrCamel(key)}
                tagValue={isObject(value) ? JSON.stringify(value) : value}
              />
            );
          }
          return null;
        })}

        {!isEmpty(props.other) && (
          <DescriptionList className="my-16">
            <DescriptionTerm className="flex gap-6">
              Other <InfoTooltip content="Additional provider parameters that affect the output" />
            </DescriptionTerm>
            <DescriptionDetails>
              <CodePreview language="json" wrapLongLines className=" text-12-16">
                {JSON.stringify(props.other, null, 2)}
              </CodePreview>
            </DescriptionDetails>
          </DescriptionList>
        )}
      </div>
    </div>
  );
};
