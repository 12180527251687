import { Directory, useDirectory } from "@/services/directories.service";
import { Batch } from "@/types/app/batch";
import { File } from "@/types/app/file";
import React, { useCallback, useEffect, useState } from "react";
import { DirectoryBreadcrumbs } from "../Projects/DirectoriesBreadcrumbs";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import LoadingIndicator from "@components/library/LoadingIndicator";
import Button from "@components/library/Button";
import { LoadingIcon } from "@components/library/Icons";
import { Check } from "@phosphor-icons/react";
import { classNames, ConditionalWrapper } from "@/lib/utils";
import { EntityLabel } from "@components/library/Entities/EntityLabel";
import { EnvironmentTag } from "@components/library/EnvironmentTag";
import dayjs from "dayjs";
import { Search } from "./FilePicker";
import { BatchDetailPanel, BatchPickerItem } from "./BatchPickerStep";
import { VersionDetailPanel, VersionPickerItem } from "./VersionPickerStep";
import { FileLabelWithPopover } from "@components/library/Entities/FileLabelWithPopover";
import { Dataset } from "@/types/app/dataset";
import { Environment } from "@/types/app/environment";
import RadioButton from "@components/library/RadioButton";
import { Checkbox } from "antd";
import { Tooltip } from "@components/library/Tooltip";
import { Tag } from "@components/library/Tag";
import { DynamicTimestamp, RelativeTimestamp } from "@components/atoms/Timestamp";

export type VersionSubmitProps = { version: File };

export type EnvironmentSubmitProps = {
  environment: Environment;
  file: File;
};

export type BatchSubmitProps = {
  batch: Batch;
  file: File;
};

export type EntityOnSubmitProps = VersionSubmitProps | EnvironmentSubmitProps | BatchSubmitProps;

export interface BaseEntityStepProps {
  file: File;
  multiselect: boolean;
  submitButton?: React.ReactNode;
  onClose: () => void;
  onNavigateDirectory?: (directory: Directory) => void;
  navigateDisabled?: boolean;
  showEnvironments?: boolean;
  /** Items will appear in the list but be disabled */
  disabledIds?: string[] | null;
  onSubmitted?: (props: EntityOnSubmitProps[]) => void;
  pickerItems?: EntityPickerItem[];
  // If initial items are undefined, use default environment
  // If empty array is passed, do not select any items
  // If non-empty array is passed, mark items in props.initialVersion as selected
  initialItems?: EntityPickerItem[];

  loading?: boolean;
}

export type EntityPickerStepProps = BaseEntityStepProps & {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  searchPlaceholder?: string;
} & (
    | { type: "batch"; previewProps?: { versions: File[] | undefined; datasetVersion: Dataset | null } }
    | { type: "version" }
  );

type EntityPickerItem = { disabled?: boolean } & (VersionPickerItem | BatchPickerItem);

/** The generic "second step" of the picker where you're picking a version or a specific batch */
export const EntityPickerStep = (props: EntityPickerStepProps) => {
  const { pickerItems, loading } = props;

  let previewProps = null;
  if (props.type === "batch") {
    previewProps = props.previewProps;
  }

  // Entities the user selects
  const [selectedItems, setSelectedItems] = useState<EntityPickerItem[]>(
    props.initialItems !== undefined ? props.initialItems : [],
  );

  // Set initial items if they are passed. `initialized` is used to prevent re-setting the initial items on rerenders (if initialItems changes)
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized && props.initialItems !== undefined && props.initialItems.length > 0) {
      setSelectedItems(props.initialItems);
      setInitialized(true);
    }
  }, [initialized, props.initialItems]);
  console.log({ initialized, selectedItems, initialItems: props.initialItems });

  const { directory } = useDirectory(props.file.directory_id, { keepPreviousData: true });

  // If the user passed a ReactNode that wasn't a string, we'll render that as the button.
  // Otherwise, we use the string as the button text, and fallback to a default if nothing was passed.
  const SubmitButton: React.ReactNode | undefined = props.submitButton && typeof props.submitButton !== "string";
  const submitButtonText = typeof props.submitButton === "string" ? props.submitButton : "Choose";

  const [submitting, setSubmitting] = useState(false);

  const isSelected = (item: EntityPickerItem) => {
    return selectedItems.length > 0 && !!selectedItems.find((selected) => item.id === selected.id);
  };

  const itemIsDisabled = useCallback(
    (item: EntityPickerItem) => {
      return !!props.disabledIds?.includes(item.id);
    },
    [props.disabledIds],
  );

  return (
    <div className="flex h-[376px] flex-col">
      {/* <pre className="text-10-10">{JSON.stringify({ initialItems: props.initialItems, selectedItems }, null, 2)}</pre> */}
      {/* Batch Dataset */}
      {props.type === "batch" && props.previewProps?.datasetVersion && (
        <div className="mt-[-12px] flex items-center gap-8 pb-24">
          <div className="text-14-20 font-medium text-text-base-3">Showing Batches with Dataset:</div>{" "}
          <FileLabelWithPopover file={props.previewProps.datasetVersion} size={24} displayFile />
        </div>
      )}
      {/* Breadcrumbs */}
      {directory && props.type !== "batch" && (
        <DirectoryBreadcrumbs
          className="-ml-12"
          directory={directory}
          file={props.file}
          onClick={(directory) =>
            !props.navigateDisabled && props.onNavigateDirectory && props.onNavigateDirectory(directory)
          }
          disabled={props.navigateDisabled}
          renderRoot
        />
      )}

      {/* Divider */}
      <hr className="-mx-32 mt-8 flex items-center border-stroke-base-2 px-32 pb-8" />

      {/* Main area */}
      <div className="-mx-32 -mt-8 flex grow overflow-hidden border-b border-stroke-base-2">
        {pickerItems ? (
          <>
            {/* Picker panel */}
            <div className="flex min-w-[380px] max-w-[380px] flex-col border-r border-stroke-base-2">
              {/* Search */}
              <div className="px-28 pt-12">
                <Search
                  value={props.search}
                  setValue={props.setSearch}
                  placeholder={props.searchPlaceholder ? props.searchPlaceholder : "Search ID or commit message..."}
                />
              </div>

              {/* Entity list */}
              <div className="min-h-0 grow">
                <ScrollArea.Root className="h-full">
                  <ScrollArea.Viewport className="h-full px-12 pt-6 [&>div]:!block [&>div]:!h-full">
                    {pickerItems.length > 0 ? (
                      pickerItems?.map((item) => (
                        <ConditionalWrapper
                          key={item.id}
                          condition={itemIsDisabled(item)}
                          wrapper={(children) => <Tooltip content="This item cannot be selected">{children}</Tooltip>}
                        >
                          <div className={classNames("flex flex-col gap-y-2 pb-8")}>
                            {props.multiselect ? (
                              <EntityListItem
                                file={props.file}
                                item={item}
                                selected={isSelected(item)}
                                onSelect={() => {
                                  if (itemIsDisabled(item)) return;
                                  if (isSelected(item)) {
                                    // Deselect item
                                    setSelectedItems(selectedItems.filter((sItem) => sItem.id !== item.id));
                                  } else {
                                    // Selected items will be returned in order of appearance in pickerItems
                                    setSelectedItems(
                                      pickerItems.filter((pItem) =>
                                        [...selectedItems, item].find((sItem) => sItem.id === pItem.id),
                                      ),
                                    );
                                  }
                                }}
                                onNavigate={() => {}}
                              >
                                <div className="relative top-[-2px]">
                                  <Checkbox
                                    // Silence warning from react regarding no onChange
                                    checked={isSelected(item)}
                                    disabled={itemIsDisabled(item)}
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                </div>
                              </EntityListItem>
                            ) : (
                              <EntityListItem
                                file={props.file}
                                item={item}
                                selected={isSelected(item)}
                                onSelect={() => {
                                  if (itemIsDisabled(item)) return;
                                  // No deselect as its a radio button
                                  setSelectedItems([item]);
                                }}
                                onNavigate={() => {}}
                              >
                                <RadioButton
                                  // In single pick mode, only one item is inside selectedItems at any point
                                  checked={isSelected(item)}
                                  size={16}
                                  disabled={itemIsDisabled(item)}
                                />
                              </EntityListItem>
                            )}
                          </div>
                        </ConditionalWrapper>
                      ))
                    ) : (
                      <div className="-mt-24 flex h-full items-center justify-center text-13-14 font-light text-text-base-3">
                        {loading ? "Loading..." : "No results"}
                      </div>
                    )}
                  </ScrollArea.Viewport>

                  <ScrollArea.Scrollbar
                    orientation="vertical"
                    className="z-50 flex w-[7px] overflow-hidden rounded bg-gray-100 p-2"
                  >
                    <ScrollArea.Thumb className="flex-auto rounded bg-gray-200" />
                  </ScrollArea.Scrollbar>
                </ScrollArea.Root>
              </div>
            </div>

            {/* Entity detail panel */}
            {/* Show either the single element selected or the last one if multiselected is enabled */}
            {selectedItems.length > 0 &&
              (selectedItems[selectedItems.length - 1].type === "version" ||
                selectedItems[selectedItems.length - 1].type === "environment") && (
                <VersionDetailPanel
                  selectedItem={selectedItems[selectedItems.length - 1] as VersionPickerItem}
                  file={props.file}
                />
              )}
            {selectedItems.length > 0 &&
              selectedItems[selectedItems.length - 1].type === "batch" &&
              props.type === "batch" && (
                <BatchDetailPanel
                  selectedItem={selectedItems[selectedItems.length - 1] as BatchPickerItem}
                  file={props.file}
                  previewProps={props.previewProps}
                />
              )}
          </>
        ) : (
          <div className="flex h-full w-full items-center justify-center text-13-14 font-light text-text-base-3">
            {loading ? <LoadingIndicator className="h-24 w-24" /> : <>This file has no versions</>}
          </div>
        )}
      </div>

      {/* Buttons */}
      <div className="relative flex justify-between  pt-16">
        {/* Cancel */}

        <Button styling="outline" shade="gray" size={32} onClick={props.onClose}>
          Cancel
        </Button>

        {/* Submit */}
        {SubmitButton || (
          <Button
            styling="solid"
            shade="black"
            size={32}
            disabled={selectedItems.length === 0 || submitting || selectedItems.some((item) => itemIsDisabled(item))}
            IconLeft={submitting ? LoadingIcon : Check}
            onClick={() => {
              if (!selectedItems || selectedItems.length === 0 || !props.onSubmitted) return;
              setSubmitting(true);
              const toSubmit: EntityOnSubmitProps[] = selectedItems.map((item) => {
                switch (item.type) {
                  case "version":
                    return { version: item.version };
                  case "environment":
                    return { environment: item.environment, file: props.file };
                  case "batch":
                    return { batch: item.batch, file: props.file };
                }
              });
              props.onSubmitted(toSubmit);
            }}
          >
            {submitButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

interface EntityListItemProps {
  file: File;
  item: EntityPickerItem;
  selected: boolean;
  onSelect: () => void;
  onNavigate: () => void;
}

const EntityListItem = ({
  file,
  item,
  selected,
  onSelect,
  onNavigate,
  children,
}: React.PropsWithChildren<EntityListItemProps>) => {
  return (
    <button
      className={classNames(
        "group/directory flex h-32 w-full items-center justify-between truncate rounded-ms px-16",
        selected ? "bg-blue-50 " : "",
      )}
      onClick={onSelect}
      onDoubleClick={onNavigate}
    >
      <div className="flex min-w-0 flex-shrink-0 items-center gap-12">
        {children}
        {item.type === "version" && (
          <EntityLabel
            entity={file.type}
            committed={item.version.status === "committed"}
            deployedEnvironments={item.version.environments}
            size={24}
            versionId={item.version.version_id}
          />
        )}
        {item.type === "batch" && <EntityLabel id={item.id} entity="batch" size={24} />}
        {item.type === "environment" && (
          <div className="flex items-center gap-4">
            <EnvironmentTag environment={item.environment} size={24} />
            {item.environment.tag === "default" && <Tag color="gray">default</Tag>}
          </div>
        )}
      </div>

      {item.type === "version" && (
        <>
          <div className="ml-12 min-w-0 grow truncate text-left text-text-base-4">{item.version.commit_message}</div>
          <div className="ml-12 flex min-w-0 flex-shrink-0 items-center">
            <div className="text-12-20 font-light text-text-base-2">
              <DynamicTimestamp timestamp={item.version.created_at} windowInDays={2} />
            </div>
          </div>
        </>
      )}
      {item.type === "batch" && (
        <>
          <div className="ml-12 flex min-w-0 flex-shrink-0 items-center">
            <div className="text-12-20 font-light text-text-base-2">
              <DynamicTimestamp timestamp={item.batch.created_at} windowInDays={2} />
            </div>
          </div>
        </>
      )}
    </button>
  );
};

/**
 * Compares two dayjs dates into chronological order (oldest first)
 * @returns Negative if a < b, positive if a > b, 0 if equal
 */
export const compareDates = (a: dayjs.Dayjs, b: dayjs.Dayjs) => {
  // Validate that the dates are dayjs objects, not strings
  if (typeof a === "string" || typeof b === "string") {
    throw new Error("Invalid date(s) provided - must be dayjs objects");
  }
  if (a.isBefore(b)) return -1;
  if (a.isAfter(b)) return 1;
  return 0;
};
