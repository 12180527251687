/** @jsxImportSource @emotion/react */

import { classNames } from "./utils/classNames";

interface Props {
  className?: string;
  foreground?: string;
  background?: string;
}

const LoadingIndicator = ({ className, foreground = "#b4b8f6", background = "#ffffff" }: Props) => {
  return (
    <div
      className={classNames("animate-spin", className)}
      css={{
        "borderRadius": "50%",
        "background": `linear-gradient(to right, ${foreground} 10%, ${foreground}00 42%)`,
        "position": "relative",
        ":before": {
          width: "50%",
          height: "50%",
          background: foreground,
          borderRadius: "100% 0 0 0",
          position: "absolute",
          top: 0,
          left: 0,
          content: '""',
        },
        ":after": {
          background,
          width: "75%",
          height: "75%",
          borderRadius: "50%",
          content: '""',
          margin: "auto",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        },
      }}
    />
  );
};

export default LoadingIndicator;
