import { classNames } from "lib/utils";
import { memo } from "react";
import { ModelProvider } from "@/services/playground.service";

const UnmemoizedProviderLogo = ({ name, className }: { name: ModelProvider; className: string }) => {
  switch (name) {
    case ModelProvider.openai:
      return <img src="/assets/providers/openai-logomark.svg" alt={name} className={className} />;
    case ModelProvider.anthropic:
      return <img src="/assets/providers/anthropic-logomark.svg" alt={name} className={className} />;
    case ModelProvider.deepmind:
      return <img src="/assets/providers/deepmind-logomark.svg" alt={name} className={className} />;
    case ModelProvider.cohere:
      return <img src="/assets/providers/cohere-logomark-new.svg" alt={name} className={className} />;
    case ModelProvider.openai_azure:
      return <img src="/assets/providers/azure-logomark.svg" alt={name} className={className} />;
    case ModelProvider.replicate:
      return <img src="/assets/providers/replicate-logomark.svg" alt={name} className={className} />;
    case ModelProvider.huggingface:
      return (
        <img
          src="/assets/providers/huggingface-logomark.svg"
          alt={name}
          className={classNames("scale-110 grayscale", className)}
        />
      );
    case ModelProvider.google:
      return <img src="/assets/providers/google-mark.svg" alt={name} className={className} />;
    case ModelProvider.bedrock:
      return <img src="/assets/providers/aws.svg" alt={name} className={classNames("scale-150", className)} />;

    case ModelProvider.groq:
      return <img src="/assets/providers/groq.svg" alt={name} className={className} />;
    case ModelProvider.deepseek:
      return <img src="/assets/providers/deepseek.svg" alt={name} className={className} />;
    default:
      return <div className="max-w-[40px] truncate font-bold">{name}</div>;
  }
};

export const ProviderLogo = memo(UnmemoizedProviderLogo);
