import Avatar, { AvatarUser } from "@components/atoms/Avatar";
import { Dayjs } from "dayjs";

const Root = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center gap-12">{children}</div>
);

export const AuthorActivityAvatar = ({ user }: { user: AvatarUser }) => <Avatar user={user} size={24} />;

const Text = ({ children }: { children: React.ReactNode }) => (
  <div className="flex h-full items-center gap-4 truncate">{children}</div>
);

const Name = ({ children }: { children: React.ReactNode }) => (
  <span className="inline text-12-16 font-medium text-text-base-1">{children}</span>
);

const ActionAndTimestamp = ({ action, timestamp }: { action: string; timestamp: Dayjs }) => (
  <span
    className="inline truncate text-12-16 font-normal text-text-base-2"
    // Unclear why, but this has occassionally errored because "format is not a function"
    title={timestamp?.format("YYYY-MM-DD HH:mm:ss")}
  >
    {action} {timestamp?.fromNow()}
  </span>
);

export const AuthorActivity = {
  Root,
  Avatar: AuthorActivityAvatar,
  Text,
  Name,
  ActionAndTimestamp,
};
