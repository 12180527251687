import { useOrganizationEnvironments } from "@/services/environments.service";
import { File } from "@/types/app/file";
import { useMemo, useState } from "react";
import { useFileEnvironments, useVersions } from "@/services/files.service";
import { BaseEntityStepProps, EntityPickerStep, compareDates } from "./EntityPickerStep";
import { VersionPopover } from "@components/library/Entities/VersionPopover";
import { Environment } from "@/types/app/environment";
import { isEmpty } from "lodash";

interface VersionPickerStepProps extends BaseEntityStepProps {
  /** Show only versions with this status - null will show all. */
  status?: "committed" | "uncommitted" | null;
}

export type VersionPickerItem = { disabled?: boolean } & (
  | { type: "version"; id: string; version: File }
  | { type: "environment"; id: string; environment: Environment }
);

export const VersionPickerStep = (props: VersionPickerStepProps) => {
  const [search, setSearch] = useState("");

  const { versions, loading: versionsLoading } = useVersions(props.file.id, props.status || null);
  const { environments, loading: environmentsLoading } = useOrganizationEnvironments();

  const loading = versionsLoading || (props.showEnvironments && environmentsLoading);

  const pickerItems: VersionPickerItem[] | undefined = useMemo(() => {
    if (versionsLoading) return undefined;
    if (props.showEnvironments && environmentsLoading) return undefined;

    const versionsFiltered: VersionPickerItem[] = versions!
      .filter(
        (version) =>
          version.version_id.toLowerCase().includes(search.toLowerCase()) ||
          (version.commit_message && version.commit_message.toLowerCase().includes(search.toLowerCase())),
      )
      // Sort versions by committed/uncommitted and then by created_at
      .sort((a, b) => {
        if (a.status === b.status) {
          return -compareDates(a.created_at, b.created_at);
        }
        return a.status === "committed" ? -1 : 1;
      })
      .map((version) => ({ type: "version", id: version.version_id, version }) satisfies VersionPickerItem);
    const environmentsFiltered: VersionPickerItem[] =
      props.showEnvironments && environments
        ? environments
            .filter((environment) => environment.name.toLowerCase().includes(search.toLowerCase()))
            // Only show environments that have a deployed version
            .filter((environment) => props.file.environments.some((env) => env.id === environment.id))
            .sort((a, b) => {
              // Show default environment first, then order by created at (desc)
              if (a.tag === "default") return -1;
              if (b.tag === "default") return 1;
              return -compareDates(a.created_at, b.created_at);
            })
            .map(
              (environment) => ({ type: "environment", id: environment.id, environment }) satisfies VersionPickerItem,
            )
        : [];

    return props.showEnvironments ? [...environmentsFiltered, ...versionsFiltered] : versionsFiltered;
  }, [
    versionsLoading,
    props.showEnvironments,
    props.file.environments,
    environmentsLoading,
    versions,
    environments,
    search,
  ]);

  const initialItemsWithFallback =
    props.initialItems && !isEmpty(props.initialItems)
      ? props.initialItems
      : // Fall back to first item if initialItems is empty.
        // This is usually the latest committed version, as we show committed versions before uncommitted.
        ((pickerItems?.slice(0, 1) ?? []) as VersionPickerItem[]);

  return (
    <EntityPickerStep
      {...props}
      type="version"
      loading={loading}
      pickerItems={pickerItems ?? []}
      initialItems={initialItemsWithFallback ?? []}
      search={search}
      setSearch={setSearch}
    />
  );
};

interface VersionDetailPanelProps {
  selectedItem: VersionPickerItem | null;
  file: File;
}

export const VersionDetailPanel = ({ selectedItem, file }: VersionDetailPanelProps) => {
  const { fileEnvironments } = useFileEnvironments(file.id);

  if (!selectedItem) {
    return (
      <div className="flex h-full grow items-center justify-center text-13-14 font-light text-text-base-3">
        No versions selected
      </div>
    );
  }
  if (selectedItem.type === "environment") {
    const environment = selectedItem.environment;
    const deployedVersion = fileEnvironments?.find((env) => env.id === environment.id)?.file;

    return (
      <div className="flex h-full w-full flex-col">
        <div className="mx-16 mt-16 rounded-ms border border-icon-success-2 bg-green-50 px-12 py-10 text-text-success-1">
          <span className="font-bold">{environment.name}</span> deployment can change over time
        </div>
        <div className="flex h-full w-full flex-col gap-16 p-16">
          {deployedVersion ? (
            <VersionPopover file={deployedVersion} noRoot />
          ) : (
            <span className="italic text-text-base-4">No deployed Version</span>
          )}
        </div>
      </div>
    );
  }
  const version = selectedItem.version;
  return (
    <div className="flex h-full w-full flex-col gap-16 p-16">
      <VersionPopover file={version} noRoot />
    </div>
  );
};
