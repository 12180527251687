import { PRIVACY_CLASSNAMES } from "@/lib/analytics/shared";
import { getMessageImageContent, getTextFromMessage } from "@/lib/messages";
import { classNames, pluralise } from "@/lib/utils";
import { ChatMessageData, ImageChatContent, ToolCall } from "@/services/playground.service";
import { ErrorTag } from "@components/library/v2/Tag";
import { MessageRoleTag } from "@components/playground/MessageRoleTag";
import { InputTag } from "@components/playground/VariableTags";
import { PhotographIcon } from "@heroicons/react/outline";
import { isObject } from "lodash";
import React from "react";

export const NUM_INPUT_ROWS = 2;

// each row is text-13-20, gap-4, py-10
export const LOG_ROW_HEIGHT = (NUM_INPUT_ROWS + 1) * 20 + NUM_INPUT_ROWS * 4 + 20;

const rowTruncationClasses = (rows: number) =>
  classNames(
    rows === 1 && "truncate",
    rows > 1 && "whitespace-normal",
    rows === 2 && " line-clamp-2",
    rows === 3 && "line-clamp-3",
    rows === 4 && "line-clamp-4",
  );

interface NoneProps {
  className?: string;
}

/** Use when the value is valid but is null/None */
export const None: React.FC<NoneProps> = ({ className }) => (
  <span className={classNames("select-none font-mono text-text-base-4", className)}>None</span>
);

export const InputsCell = ({ inputs }: { inputs: Record<string, string | number | null> }) => {
  if (inputs === null || inputs === undefined || Object.keys(inputs).length === 0) {
    return <None />;
  }
  const numberOfInputs = Object.keys(inputs).length;

  const inputRows = Object.entries(inputs)
    .slice(0, NUM_INPUT_ROWS)
    .map(([key, value]) => (
      <CellContentRow key={key} role={<InputTag>{key}</InputTag>}>
        {/* TODO: protective casting to string as we're letting in objects as inputs. */}
        {isObject(value) ? JSON.stringify(value, null, 2) : String(value)}
      </CellContentRow>
    ));

  return (
    // Just pt padding not py because it seems to cause the table border to disappear
    // and that's probably just some annoying ag-grid thing.
    <div className={classNames("flex flex-col gap-4 pt-10 text-13-20", PRIVACY_CLASSNAMES["high"])}>
      {inputRows}
      {numberOfInputs > NUM_INPUT_ROWS ? (
        <div>
          <span className="px-4 text-12-12 text-text-base-4">
            +{numberOfInputs - NUM_INPUT_ROWS} input{pluralise(numberOfInputs - NUM_INPUT_ROWS)}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export const MessagesCell = ({
  messages,
  chatTemplate,
}: {
  messages: ChatMessageData[];
  chatTemplate?: ChatMessageData[];
}) => {
  if (messages === null || messages === undefined || messages.length === 0) {
    return <None />;
  }
  // Remember that messages are from oldest to newest, and that final message
  // is not in the messages prop of a log, it's in output_message.
  // We also do not generally want to show the templated messages,
  // so we take the last NUM_INPUT_ROWS messages, ignoring the templated messages.
  const nonTemplateMessages = messages.slice(chatTemplate?.length || 0);

  const messagesItems = nonTemplateMessages
    .slice(-NUM_INPUT_ROWS)
    .map((message, index) => <CellMessage key={index} message={message} />);

  return (
    <div className="flex flex-col gap-4 pt-10 text-13-20">
      {messagesItems}

      {nonTemplateMessages.length > NUM_INPUT_ROWS ? (
        <div className={PRIVACY_CLASSNAMES["high"]}>
          {/* <MessageRoleTag>+ {messages.length - NUM_INPUT_ROWS}</MessageRoleTag> */}
          <span className="truncate px-4 text-12-12 text-text-base-4">
            +{nonTemplateMessages.length - NUM_INPUT_ROWS} prior message
            {pluralise(nonTemplateMessages.length - NUM_INPUT_ROWS)}
          </span>
        </div>
      ) : null}
    </div>
  );
};

/** This can be an error, output message or an output */
export const OutputCell = ({
  output_message,
  output,
  error,
}: {
  output_message: ChatMessageData | null;
  output: React.ReactNode | null;
  error: string | null;
}) => {
  if (error) {
    return (
      <div
        className={classNames("flex gap-4 truncate py-10 text-13-20 text-text-danger-2", PRIVACY_CLASSNAMES["high"])}
      >
        <CellContentRow rows={NUM_INPUT_ROWS + 1} role={<ErrorTag className="">Error</ErrorTag>}>
          {error}
        </CellContentRow>
      </div>
    );
  }
  if (output_message) {
    return (
      <div className={classNames("py-10", PRIVACY_CLASSNAMES["high"])}>
        <CellMessage message={output_message} rows={NUM_INPUT_ROWS + 1} />
      </div>
    );
  }
  if (output) {
    return (
      <div
        className={classNames("pt-10 text-13-20", rowTruncationClasses(NUM_INPUT_ROWS + 1), PRIVACY_CLASSNAMES["high"])}
      >
        {output}
      </div>
    );
  }
  return <None />;
};

export const CellMessage = ({
  message,
  rows,
  hideRole,
}: {
  message: ChatMessageData;
  rows?: number;
  hideRole?: boolean;
}) => {
  const name = message.name;
  const textContent = getTextFromMessage(message.content);
  const imageAttachments = getMessageImageContent(message.content);
  const toolAttachments = message.tool_calls;

  return (
    <div key={textContent}>
      <CellContentRow role={<MessageRoleTag>{message.role}</MessageRoleTag>} rows={rows} hideRole={hideRole}>
        <span className={classNames(message.role === "tool" && "font-mono text-[12px]", PRIVACY_CLASSNAMES["high"])}>
          {/* These float top right so come before the text */}
          {imageAttachments?.map((imageAttachment) => (
            <ImageAttachment key={imageAttachment.image_url.url} imageAttachment={imageAttachment} />
          ))}
          <span className="">{name} </span> {textContent}
          {message.tool_calls?.map((toolCall) => <ToolCallAttachment key={toolCall.id} toolCall={toolCall} />)}
        </span>
      </CellContentRow>
    </div>
  );
};

const ToolCallAttachment = ({ toolCall }: { toolCall: ToolCall }) => {
  return (
    <div
      className="inline-flex min-w-0 max-w-full truncate rounded-full border border-stroke-base-3 bg-background-base-1 px-8 font-mono text-[12px] leading-[20px]"
      key={toolCall.function.name}
    >
      <span className="font-bold text-text-base-1">{toolCall.function.name}</span>
      <span className="min-w-0 truncate text-text-base-2">({toolCall.function.arguments})</span>
    </div>
  );
};

const ImageAttachment = ({ imageAttachment }: { imageAttachment: ImageChatContent }) => {
  return (
    <div
      className="float-right -mb-1 mt-1 items-center gap-6 truncate rounded-full border border-stroke-base-3 px-8 font-mono text-[12px] leading-[20px]"
      key={imageAttachment.image_url.url}
    >
      <PhotographIcon className="h-14 w-14  shrink-0 text-icon-base-3" />
    </div>
  );
};

interface CellContentRowProps {
  role?: React.ReactNode;
  rows?: number;
  hideRole?: boolean;
  children: React.ReactNode;
}

/** Like a content row but super small and truncated so it can fit in a cell */
export const CellContentRow = ({ role, children, rows = 1, hideRole, ...props }: CellContentRowProps) => {
  return (
    <div
      className={classNames(
        !hideRole ? "grid grid-cols-[66px_auto]" : "",
        "items-start gap-4 truncate text-13-20",
        "max-w-full",
        "overflow-hidden",
        "whitespace-nowrap",
      )}
      {...props}
    >
      {role && !hideRole ? <span className="">{role}</span> : null}
      <div className={classNames("w-full ", rowTruncationClasses(rows))}>{children}</div>
    </div>
  );
};
