import { IconButtonLink } from "@components/atoms/IconButtonLink";
import { EntityIcon } from "@components/library/Entities/EntityIcon";
import { EntityLabel, FileEntityLabelProps } from "@components/library/Entities/EntityLabel";

/**
 * https://www.figma.com/file/Ya7mCOIZJp1UdBCH3VxnEL/Components-v2?type=design&node-id=2239-7883&mode=design&t=c1YrAB68h7OX5HYx-0
 */
// TODO: I don't find all this componentization useful. It's just misdirection and consequences are harder to reason about.
// Just using divs and tailwind classes in the component is clearer.
const Root = ({ children }: { children: React.ReactNode }) => (
  <div className="flex h-20 w-full items-center gap-12 self-stretch">{children}</div>
);

const Icon = (props: Omit<React.ComponentPropsWithoutRef<typeof EntityIcon>, "size">) => (
  <EntityIcon size={16} {...props} />
);

const Title = ({ children }: { children: React.ReactNode }) => (
  <div className="flex min-w-0 items-center gap-8">{children}</div>
);

const File = ({ file }: { file: string }) => (
  <span className="truncate text-13-14 font-medium text-text-base-1">{file}</span>
);

export const VersionLabel = ({
  entity,
  deployedEnvironments,
  versionId,
  committed,
}: Pick<FileEntityLabelProps, "entity" | "deployedEnvironments" | "committed" | "versionId">) => {
  return (
    <div className="shrink-0">
      <EntityLabel
        entity={entity}
        size={20}
        versionId={versionId}
        deployedEnvironments={deployedEnvironments}
        committed={committed}
        interactive
      />
    </div>
  );
};

const LinkButton = ({ href, target = "_blank", tooltip }: { href: string; target?: string; tooltip?: string }) => {
  return (
    <div className="flex shrink-0 grow justify-end">
      <IconButtonLink tooltip={tooltip} href={href} target={target} size={24} />
    </div>
  );
};

export const Header = {
  Root,
  Icon,
  Title,
  File,
  VersionLabel,
  LinkButton,
};
