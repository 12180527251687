import { classNames } from "@/lib/utils";
import * as RadixPopover from "@radix-ui/react-popover";
import { useRef, useState, ReactNode } from "react";

type HoverPopoverProps = {
  trigger: ReactNode;
  content: ReactNode;
  arrow?: boolean;
  tooltipContentProps?: React.ComponentProps<typeof RadixPopover.Content>;
  openDelay?: number;
  closeDelay?: number;
  triggerOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  side?: RadixPopover.PopoverContentProps["side"];
  align?: RadixPopover.PopoverContentProps["align"];
  /**
   * If provided, this will control the open state of the popover.
   * Otherwise, the popover will be uncontrolled and open state will be managed internally.
   */
  open?: boolean;
  /**
   * Callback function to control the open state of the popover.
   * Best paired with `open` so that you can still open this on hover.
   */
  onOpenChange?: (open: boolean) => void;
};

export const HoverPopover = ({
  trigger,
  content,
  arrow = false,
  tooltipContentProps,
  openDelay = 100,
  closeDelay = 100, // Be careful with making this shorter, because of the animation takes 400ms.
  triggerOnClick,
  open: controlledOpen,
  side = "bottom",
  align = "center",
  onOpenChange,
}: HoverPopoverProps) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);
  const hoverTimeout = useRef<number | null>(null);

  const isControlled = controlledOpen !== undefined;
  const open = isControlled ? controlledOpen : uncontrolledOpen;

  const handleOpenChange = (newOpen: boolean) => {
    if (!isControlled) {
      setUncontrolledOpen(newOpen);
    }
    onOpenChange?.(newOpen);
  };

  const handleMouseEnter = () => {
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
    hoverTimeout.current = window.setTimeout(() => {
      handleOpenChange(true);
    }, openDelay);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
    hoverTimeout.current = window.setTimeout(() => {
      handleOpenChange(false);
    }, closeDelay);
  };

  return (
    <RadixPopover.Root open={open}>
      <RadixPopover.Trigger
        // There was a UI bug where the EntityLabel, when wrapped in a div would have a slightly larger box than intended.
        // For example a 24px EL would have a surrounding box of 25.5px.
        // I think this is because Radix wraps the content in a button, and a button will seem to take the line height of the page (default 24px).
        // Why that goes to 25.5px... I don't know. But line height = 1 seems to fix it, hence className="leading-none"
        className="inline-flex min-w-0 shrink grow items-start leading-none focus-within:ring-0 focus:outline-none focus:ring-0"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={(event) => {
          // We cancel the timeout to avoid race condition when user quickly moves mouse in and clicks.
          // The timeout inside handleMouseEnter was stealing focus from the click event.
          if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
          if (triggerOnClick) triggerOnClick(event);
        }}
      >
        {trigger}
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          side={side}
          align={align}
          onKeyDown={(e) => {
            // Prevent keyboard navigation interacting with the rest of the app.
            e.stopPropagation();
          }}
          onMouseEnter={() => {
            handleOpenChange(true);
            if (hoverTimeout.current) clearTimeout(hoverTimeout.current);
          }}
          onMouseLeave={handleMouseLeave}
          className={classNames(
            "relative z-[1010]",
            // Antd drawer is z-[1000], and our modals are z-[1001], tooltips are z-[1050].
            // Some invisible padding given to make the mouse over area larger.
            // ag-custom-component-popup to enable interaction with this content to not close the ag grid filter popup panel
            // pointer-events-auto to allow interaction when this is used within a modal as pointer-events-none is set on the page body
            "ag-custom-component-popup pointer-events-auto relative z-[1010] outline-none",
            // Note we put MOUSE_PADDING on the side nearest the trigger so that the mouse can move into the tooltip without it closing.
            "data-[side=bottom]:pt-6 data-[side=left]:pr-6 data-[side=right]:pl-6 data-[side=top]:pb-6",
            "slide-in-fade-in",
            tooltipContentProps?.className,
          )}
          sideOffset={tooltipContentProps?.sideOffset || 0}
          collisionPadding={tooltipContentProps?.collisionPadding || 8}
          {...tooltipContentProps}
        >
          {content}
          {arrow && (
            <RadixPopover.Arrow asChild>
              <div className="relative top-[-3px] h-8 w-8 rotate-45 border-b border-r border-gray-150 bg-white" />
            </RadixPopover.Arrow>
          )}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};
