import { useBatches } from "@/services/batches.service";
import { Batch } from "@/types/app/batch";
import { File } from "@/types/app/file";
import { useMemo, useState } from "react";
import { EntityPopover } from "@components/library/Entities/shared/EntityPopover";
import { Dataset } from "@/types/app/dataset";
import { useVersions } from "@/services/files.service";
import { EntityIcon } from "@components/library/Entities/EntityIcon";
import { EntityLabel } from "@components/library/Entities/EntityLabel";
import { BaseEntityStepProps, EntityPickerStep } from "./EntityPickerStep";

interface BatchPickerStepProps extends BaseEntityStepProps {
  versionProps?: { version: File | null; datasetVersion: Dataset | null };
}

export type BatchPickerItem = { type: "batch"; id: string; batch: Batch };

/** Select a Batch (which is related to a specific File Version) */
export const BatchPickerStep = (props: BatchPickerStepProps) => {
  const { versions } = useVersions(props.file.id);

  const [search, setSearch] = useState("");
  const { batches, loading: batchesLoading } = useBatches(
    props.file.id,
    props.versionProps?.version?.version_id,
    props.versionProps?.datasetVersion?.version_id,
  );

  const pickerItems: BatchPickerItem[] | undefined = useMemo(() => {
    if (batchesLoading) return undefined;

    const batchesFiltered =
      batches?.records
        ?.filter((batch) => batch.id.toLowerCase().includes(search.toLowerCase()))
        .map((batch) => ({ type: "batch", id: batch.id, batch }) satisfies BatchPickerItem) || [];

    return batchesFiltered;
  }, [batches, batchesLoading, search]);

  return (
    <EntityPickerStep
      {...props}
      type="batch"
      loading={batchesLoading}
      pickerItems={pickerItems}
      search={search}
      setSearch={setSearch}
      searchPlaceholder="Search Batches"
      previewProps={{
        versions: versions,
        datasetVersion: props.versionProps?.datasetVersion || null,
      }}
    />
  );
};

interface BatchDetailPanelProps {
  selectedItem: BatchPickerItem | null;
  file: File;
  previewProps?: {
    versions: File[] | undefined;
    datasetVersion: Dataset | null;
  };
}

export const BatchDetailPanel = ({ selectedItem, file, previewProps }: BatchDetailPanelProps) => {
  if (!selectedItem) {
    return (
      <div className="flex h-full grow items-center justify-center text-13-14 font-light text-text-base-3">
        No batches selected
      </div>
    );
  }

  return (
    <div className="flex grow flex-col justify-between px-16 py-16">
      <div className="flex items-center gap-12">
        <EntityIcon type={file.type} size={20} />
        <div className="text-13-20 font-medium text-gray-950">{file.name}</div>
        <EntityLabel id={selectedItem.batch.id} entity="batch" size={24} />
      </div>

      <div className="flex items-center">
        {selectedItem.batch.created_by && (
          <EntityPopover.Author.Root>
            <EntityPopover.Author.Avatar user={selectedItem.batch.created_by} />
            <EntityPopover.Author.Text>
              <EntityPopover.Author.Name>{selectedItem.batch.created_by.full_name}</EntityPopover.Author.Name>
              <EntityPopover.Author.ActionAndTimestamp
                action={selectedItem.batch.created_by === null ? "Committed" : "committed"}
                timestamp={selectedItem.batch.created_at}
              />
            </EntityPopover.Author.Text>
          </EntityPopover.Author.Root>
        )}
      </div>
    </div>
  );
};
