// Use InputSpan if this is within a form that seperately submits

import { classNames } from "@/lib/utils";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

export type InputSpanProps = {
  value: string;
  setValue: (value: string) => void;
  onCancel?: () => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

/** An auto-width input element that can be used like a span.
 *
 * Has all the benefits of an input element (validation, focus, etc)
 * with the look of a contenteditable span. Element `input` usually has fixed 
// width and does not expand to its content, leading to empty space and horizontal scroll/clipping.
 */
export const InputSpan = forwardRef<HTMLInputElement, InputSpanProps>(({ value, setValue, ...props }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  // useImperativeHandle is utilized to customize what parts of your component are exposed to
  // the parent components, specifically ensuring that inputRef.current is exposed when the parent accesses ref.
  // https://chat.openai.com/share/1217a645-b43b-48b4-aaa3-fdbf2b93c0f8
  // Could use the same ref that's passed in but this enables you to not *have* to pass in a ref.
  // Edit: @harry-humanloop points out that we could use mergeRefs here and that this
  // imperative handle is more typically used for exposing methods to the parent.
  // https://react.dev/reference/react/useImperativeHandle#usage
  useImperativeHandle(ref, () => inputRef.current!);

  useEffect(() => {
    if (inputRef.current && hiddenSpanRef.current) {
      inputRef.current.style.width = `${hiddenSpanRef.current.offsetWidth}px`;
    }
    // The `inputRef.current` and `hiddenSpanRef.current` deps aren't valid dependencies
    // because mutating them doesn't re-render the component.
    // However, without them here the effect doesn't run on the first render and
    // I'm not sure of a better way to fix that.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputRef.current, hiddenSpanRef.current]);

  return (
    <>
      <span ref={hiddenSpanRef} className="invisible absolute -z-[999999]">
        {value}
      </span>
      <input
        {...props}
        style={{
          minWidth: "20px",
          width: "auto",
        }}
        onBlur={props.onBlur}
        className={classNames("border-none bg-transparent p-0 outline-none", props.className)}
        ref={inputRef}
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
        }}
      />
    </>
  );
});

InputSpan.displayName = "InputSpan";
