import { usePointerEventsCleanup } from "@/lib/use-pointer-events-cleanup";
import { classNames } from "@/lib/utils";
import { ContextMenu } from "@components/library/ContextMenu";
import IconButton, { IconButtonProps } from "@components/library/IconButton";
import { DotsHorizontalIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useState } from "react";

interface InlineMenuProps extends Omit<IconButtonProps, "Icon"> {
  // These should be of type ContextMenuItem[] withinDropdownMenu
  children: React.ReactNode;
  /** Classname passed to `ContextMenu.Items`. Useful for overriding the z-index. By default, the menu appears below popovers. */
  itemsClassName?: string;
  /** The orientation of the dots in the icon button. */
  dotsOrientation?: "horizontal" | "vertical";
}

/** Simple three dots icon button with overflow menu 
 * 
 * 
 * Usage:
 *     <InlineMenu size={24} shade="black">
        <ContextMenu.Item
          withinDropdownMenu
          IconLeft={ToolIcon}
          onClick={() => {
            addToolCall();
          }}
        >
          Insert tool call
        </ContextMenu.Item>
      </InlineMenu>
*/
export const InlineMenu = ({ children, itemsClassName, dotsOrientation = "vertical", ...props }: InlineMenuProps) => {
  const [open, setOpen] = useState(false);

  // Found that within the Prompt Editor the dropdown on a message would close,
  // but leave the page uninteractive.
  usePointerEventsCleanup(open);
  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild disabled={props.disabled}>
        <IconButton
          Icon={dotsOrientation === "horizontal" ? DotsHorizontalIcon : DotsVerticalIcon}
          shade={"gray"}
          active={open}
          disabled={props.disabled}
          {...props}
          // Custom CSS class (.portal-active) to allow parents to use `has:portal-active`
          //  so they can be styled when the context is active in a portal.
          // Primarily used for focus/hover styling when the context is active in a portal,
          //  which the portal breaks.
          className={classNames(props.className, open ? "portal-active" : "")}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        {/* Prevent keyboard navigation from interacting with the rest of the app */}
        <div onKeyDown={(e) => e.stopPropagation()}>
          <ContextMenu.Items
            isDropdownMenu
            align="start"
            sideOffset={4}
            className={classNames("z-[1001] mr-20", itemsClassName)}
          >
            {children}
          </ContextMenu.Items>
        </div>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
