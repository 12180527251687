import { getShortId, segmentLongId } from "@/lib/string";
import { hlToast, ToastVariant } from "./Toast";
import Tooltip from "./Tooltip";
import { DuplicateIcon } from "@heroicons/react/outline";
import { Copy } from "@phosphor-icons/react";

interface Props {
  id: string;
  prefix?: boolean;
  type?: "session" | "flow";
  full?: boolean;
  copyIcon?: boolean;
}

const IdDisplay = ({ id, prefix = true, type, full = false, copyIcon = false }: Props) => {
  const name = type === "flow" ? "Flow ID" : type === "session" ? "Session ID" : "ID";
  const segmentedLongId = segmentLongId(id);
  const displayId = full ? (
    id
  ) : type === "flow" ? (
    getShortId(id, 8)
  ) : prefix ? (
    <span className="font-mono">
      {segmentedLongId.prefix}
      {segmentedLongId.shortId}…
    </span>
  ) : (
    <span className="font-mono">{segmentedLongId.shortId}</span>
  );
  return (
    <ClickToCopyWrapper textToCopy={id} name={name}>
      <div className="inline-flex items-center gap-4 ">
        {displayId} {copyIcon && <Copy className="opacity-50 group-hover/copy:opacity-100" size={14} />}
      </div>
    </ClickToCopyWrapper>
  );
};

export default IdDisplay;

export const ClickToCopyWrapper = ({
  children,
  textToCopy,
  name,
}: {
  children: React.ReactNode;
  textToCopy: string;
  name: string;
}) => {
  return (
    <Tooltip delayDuration={200} content={<span className="text-11-12">{`Copy ${name}`}</span>}>
      <span
        className="group/copy cursor-pointer select-none"
        onClick={() =>
          navigator.clipboard.writeText(textToCopy).then(() => {
            hlToast({ variant: ToastVariant.Success, title: `${name} copied to clipboard!` });
          })
        }
      >
        {children}
      </span>
    </Tooltip>
  );
};
