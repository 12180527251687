import { AxiosPromise } from "axios";
import { Dayjs } from "dayjs";
import { ApiService } from "./api.service";
import { User } from "@/types/app/user";
import { ReplaceKeysInType, parseTimestampsInResponse } from "./utils";
import { Batch } from "@/types/app/batch";
import useSWR, { SWRConfiguration } from "swr";
import { getAuthToken } from "@/lib/use-auth";
import useSWRInfinite from "swr/infinite";
import { Page } from "@/types/generic";
import { useMemo } from "react";

export interface CreateBatchRequest {
  version_id: string;
  dataset_version_id: string;
}

type BatchResponse = ReplaceKeysInType<Batch, "created_at" | "updated_at", string>;

export const createBatch = async (request: CreateBatchRequest): AxiosPromise<Batch> => {
  const response = await ApiService.post(`/v5/batches`, request);
  return { ...response, data: parseBatchResponse(response.data) };
};

const parseBatchResponse = (response: BatchResponse): Batch => {
  return parseTimestampsInResponse(response, ["created_at", "updated_at"]);
};

const getBatchesParams = (versionId?: string, datasetVersionId?: string, fileId?: string) => {
  const queryParams = [];
  if (fileId) {
    queryParams.push(`file_id=${fileId}`);
  }
  if (versionId) {
    queryParams.push(`version_id=${versionId}`);
  }
  if (datasetVersionId) {
    queryParams.push(`dataset_version_id=${datasetVersionId}`);
  }

  return `?${queryParams.join("&")}`;
};

export const useBatches = (
  fileId: string | undefined,
  versionId?: string,
  datasetVersionId?: string,
  swrOptions: SWRConfiguration<BatchResponse> = {},
) => {
  const { data, error, mutate } = useSWR<Page<BatchResponse>>(
    fileId ? [`/v5/batches${getBatchesParams(versionId, datasetVersionId, fileId)}`, getAuthToken()] : null,
    {},
  );

  const batches = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return {
      ...data,
      records: data.records.map((batch) => parseBatchResponse(batch)),
    };
  }, [data]);

  return { batches, error, loading: !data && !error, mutate };
};
