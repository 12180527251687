import { useActiveOrganization } from "@/context/ActiveOrganizationProvider";
import { AxiosPromise } from "axios";
import { getAuthToken } from "lib/use-auth";
import useSWR, { SWRConfiguration } from "swr";
import { ApiService } from "./api.service";
import { Environment, EnvironmentResponse, parseEnvironmentResponse } from "@/types/app/environment";
import { parseTimestampsInResponse } from "./utils";

export const useOrganizationEnvironments = (swrOptions: SWRConfiguration<Environment[]> = {}) => {
  const { organization } = useActiveOrganization();
  const { data, error, mutate } = useSWR<Environment[]>(
    organization ? [`/v4/organizations/${organization.id}/environments`, getAuthToken()] : null,
    environmentFetcher,
    swrOptions,
  );
  return { environments: data, error: error, loading: !data && !error, mutate };
};

const environmentFetcher = async (...args: Parameters<typeof ApiService.getWithToken>): Promise<Environment[]> => {
  const response: EnvironmentResponse[] = await ApiService.getWithToken(...args);
  return response.map(parseEnvironmentResponse);
};

interface CreateEnvironmentRequest {
  name: string;
}

interface UpdateEnvironmentRequest {
  name?: string;
}

export const createEnvironment = (
  organizationId: string,
  request: CreateEnvironmentRequest,
): AxiosPromise<Environment> => {
  return ApiService.post(`/v4/organizations/${organizationId}/environments`, request);
};

export const updateEnvironment = (
  organizationId: string,
  environmentId: string,
  request: UpdateEnvironmentRequest,
): AxiosPromise<Environment> => {
  return ApiService.patch(`/v4/organizations/${organizationId}/environments/${environmentId}`, request);
};

export const deleteEnvironment = (organizationId: string, environmentId: string): AxiosPromise<void> => {
  return ApiService.remove(`/v4/organizations/${organizationId}/environments/${environmentId}`);
};
