/**
 * PageSectionHeader
 *
 * https://www.figma.com/file/Ya7mCOIZJp1UdBCH3VxnEL/Components-v2-%5BWIP%5D?type=design&node-id=606-17646&mode=design&t=2gon1bvi20WHeyeL-11
 */

import { classNames } from "./utils/classNames";

export const PageSectionHeader = ({
  title,
  description,
  separator,
  actionsTray,
  size = "lg",
  className,
}: {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  /** Whether this component should have a horizontal line separator at bottom */
  separator?: boolean;
  /** Actions to display on the right side of the header */
  actionsTray?: React.ReactNode;
  size?: "sm" | "md" | "lg";
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "flex w-full flex-col",
        size === "lg" && "mb-32 gap-y-8",
        size === "md" && "mb-32 gap-y-8",
        size === "sm" && "mb-24 gap-y-4",
        className,
      )}
    >
      <div
        className={classNames(
          "flex items-start justify-between gap-6",
          size === "lg" && "min-h-32",
          size === "md" && "min-h-32",
          size === "sm" && "min-h-24",
        )}
      >
        <h3
          className={classNames(
            "pt-2 font-bold",
            size === "lg" && "text-18-28",
            size === "md" && "text-16-24",
            size === "sm" && "text-14-20",
          )}
        >
          {title}
        </h3>
        {actionsTray && <div className={classNames("flex h-full items-center gap-x-8")}>{actionsTray}</div>}
      </div>
      {description && (
        <div className={classNames("flex flex-wrap gap-16 font-normal")}>
          <span
            className={classNames(
              "text-text-base-3",
              size === "lg" && "text-14-20",
              size === "md" && "text-14-20",
              size === "sm" && "text-13-20",
            )}
          >
            {description}
          </span>
        </div>
      )}
      {separator && (
        <hr
          className={classNames(
            "-my-1 h-1 w-full bg-stroke-base-3",
            size === "lg" && "mt-12 ",
            size === "md" && "mt-12",
            size === "sm" && "mt-12",
          )}
        />
      )}
    </div>
  );
};
