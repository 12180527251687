import { AuthorActivity } from "@components/library/AuthorActivity";
import { Header } from "./Header";
import { classNames } from "@components/library/utils/classNames";

/**
 * https://www.figma.com/file/Ya7mCOIZJp1UdBCH3VxnEL/Components-v2?type=design&node-id=2239-7883&mode=design&t=c1YrAB68h7OX5HYx-0
 */
const Root = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className={classNames(
        "flex w-320 max-w-sm flex-col gap-16 rounded-lg bg-background-base-1 p-16 text-text-base-1 shadow-card-sm",
      )}
    >
      {children}
    </div>
  );
};

export const CommitMessage = ({
  children,
  hasOwner,
  size = "sm",
}: {
  children: React.ReactNode;
  hasOwner: boolean;
  size: "sm" | "lg";
}) => {
  const sizeClass = size === "lg" ? "text-text-base-1 text-14-20 py-12 px-12" : "text-12-16 px-12 py-8";
  return (
    <div
      className={classNames(
        "relative grow self-stretch truncate whitespace-pre-wrap rounded-ms border border-stroke-base-3 bg-background-base-2 font-normal text-text-base-2",
        sizeClass,
      )}
    >
      {children}
      {/* Add triangle icon to show arrow */}
      {hasOwner && (
        <div className="absolute -bottom-[4px] left-8 z-10 h-6 w-6 -rotate-45 border-b border-l border-stroke-base-3 bg-background-base-2"></div>
      )}
    </div>
  );
};

/**
 * This is the generic components. Most things are the VersionPopover.
 */

export const EntityPopover = {
  Root,
  Header,
  CommitMessage,
  Author: AuthorActivity,
};
