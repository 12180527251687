import { File, FileType } from "@/types/app/file";
import { classNames } from "../utils/classNames";
import { EntityIcon } from "./EntityIcon";
import { CircleIcon } from "../Icons";
import { ROOT_DIRECTORY_NAME } from "@/lib/constants";
import { Icon } from "@phosphor-icons/react";
import { Lissajous } from "../Lissajous";
import { LissajousLogo } from "../LissajousLogo";

interface FileNameLabelProps {
  name?: string;
  type?: FileType | "directory";
  size?: 12 | 14 | 16 | 20 | 24 | 32;
  truncationLength?: number;
  /** Whether the label is interactive i.e. its within a button. Adds hover styles. */
  interactive?: boolean;
  active?: boolean;
  className?: string;
  /** Icon override */
  IconOverride?:
    | React.FunctionComponent<React.ComponentProps<"svg">>
    | React.FunctionComponent<React.ComponentProps<"img">>
    | Icon;
}
/**
 * A label for a file or directory.
 */
export const FileNameLabel = ({
  name,
  type,
  /** Size of the icon */
  size = 20,
  truncationLength = 26,
  interactive,
  active,
  className,
  IconOverride,
}: FileNameLabelProps) => {
  const fontSize = size === 12 ? "text-12-12" : size === 14 ? "text-14-14" : size === 16 ? "text-13-16" : "text-14-20";

  const isHomeDirectory = type === "directory" && name === ROOT_DIRECTORY_NAME;
  const displayName = isHomeDirectory ? "Home" : name;

  return (
    <div
      className={classNames(
        "flex items-center gap-8 truncate rounded-ms px-6  ",
        interactive && "hover:bg-background-base-35",
        active && "!bg-background-base-35",
        className,
      )}
      title={displayName}
      style={{ maxWidth: truncationLength ? `${truncationLength}ch` : "auto" }}
    >
      {IconOverride ? (
        <IconOverride className="h-16 w-16" />
      ) : isHomeDirectory ? (
        <LissajousLogo className="h-14 w-14" />
      ) : (
        <EntityIcon type={type || "loading"} size={size} />
      )}
      {displayName ? (
        <div className={classNames(fontSize, "font-semibold truncate py-4 text-text-base-1")}>{displayName}</div>
      ) : (
        <div className={classNames(fontSize, "font-semibold truncate py-4 text-text-base-3")}>Loading...</div>
      )}
    </div>
  );
};
