import { InputSpan, InputSpanProps } from "@components/molecules/InputSpan";
import { PencilSimple, X } from "@phosphor-icons/react";
import { useRef, useState } from "react";
import { Tag, TagProps } from "../library/v2/Tag";

// Note also see components/playground/PlaygroundEditorDecorators.tsx

export const InputTag = (props: Omit<TagProps, "shade">) => <TagWithTitle shade={"sky"} {...props} />;

export const EditableInputTag = ({
  size = 20,
  value,
  setValue,
  ...props
}: Omit<TagProps, "shade" | "children"> & InputSpanProps) => {
  const [draft, setDraft] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSetValue = () => {
    if (draft !== value && draft.trim() !== "") {
      setValue(draft);
    } else {
      setDraft(value);
    }
    inputRef.current?.blur();
  };

  return (
    <Tag size={size} shade={"sky"} {...props} title={value}>
      <span className="group/tag flex items-center gap-4">
        <InputSpan
          ref={inputRef}
          onBlur={() => {
            handleSetValue();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSetValue();
            if (e.key === "Escape") setDraft(value);
          }}
          value={draft}
          setValue={setDraft}
        />

        <div className="w-12 flex-none">
          {draft === value ? (
            <PencilSimple
              size={12}
              className="cursor-pointer text-gray-200 group-hover/tag:text-text-base-1"
              weight="bold"
              onClick={() => {
                inputRef.current?.focus();
              }}
            />
          ) : (
            <X
              className="cursor-pointer text-gray-200 group-hover/tag:text-text-base-1"
              weight="bold"
              // FYI mousedown occurs before blur (events goes mouse down, blur, mouse up, click in that order)
              onMouseDown={() => {
                setDraft(value);
              }}
            />
          )}
        </div>
      </span>
    </Tag>
  );
};

export const ToolTag = (props: Omit<TagProps, "shade">) => <TagWithTitle shade={"violet"} {...props} />;

/**
 * Tag that uses the `title` attribute to show `children` as a tooltip.
 *
 * Only does so if `children` is a string.
 */
export const TagWithTitle = ({ children, ...props }: TagProps) => (
  <Tag {...props} title={typeof children === "string" ? children : undefined}>
    {children}
  </Tag>
);
