import { useMonitoringFile } from "@/context/MonitoringFileContext";
import usePrevious from "@/lib/use-previous";
import { classNames } from "@/lib/utils";
import {
  Directory,
  DirectoryWithParentsAndChildren,
  useDirectory,
  useDirectoryStructure,
} from "@/services/directories.service";
import { Dataset } from "@/types/app/dataset";
import { File } from "@/types/app/file";
import { RadixAppDialog } from "@components/library/AppDialog";
import { EntityIcon } from "@components/library/Entities/EntityIcon";
import { LoadingIcon } from "@components/library/Icons";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { ArrowDown, ArrowUp, FolderSimple, MagnifyingGlass } from "@phosphor-icons/react";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DirectoryBreadcrumbs } from "../Projects/DirectoriesBreadcrumbs";
import { BatchPickerStep } from "./BatchPickerStep";
import {
  BatchSubmitProps,
  compareDates,
  EntityOnSubmitProps,
  EnvironmentSubmitProps,
  VersionSubmitProps,
} from "./EntityPickerStep";
import { VersionPickerItem, VersionPickerStep } from "./VersionPickerStep";
import { DynamicTimestamp } from "@components/atoms/Timestamp";

interface Sort {
  column: "name" | "updated_at";
  direction: "asc" | "desc";
}

// The sort state is that either no sorting is applied, or one of the two columns
// is sorted in either ascending or descending order.
type SortState = Sort | null;

export type FilePickerProps<T = File["type"]> = {
  open: boolean;
  title?: string;
  description?: string;
  initialDirectoryId?: string;
  fileTypeFilter?: File["type"] | File["type"][];
  fileIdsToExclude?: string[];
  submitButton?: React.ReactNode;
  onFileSelected?: (file: File) => void;
  /* After a version is selected and the user clicks submit */
  onClose: () => void;
  // Whether to reset the state of the picker when it is closed, default is false
  resetOnClose?: boolean;
  // Whether to show Environments as options in the Version Picker.
  // Note that this will only show environments that have a deployed version.
  showEnvironments?: boolean;
  /** Whether to disable the ability to navigate back to file selection.
   * Only applicable for the Batch/Version Pickers. (The File Picker is always navigable.)
   * Default is false. */
  navigateDisabled?: boolean;
  /** Disabled Version/Batch/Environment IDs - not File IDs */
  disabledIds?: string[] | null;
} & (
  | {
      multiselect: true;
      onVersionSubmitted?: (versions: VersionSubmitProps[]) => void;
      onEnvironmentSubmitted?: (environments: EnvironmentSubmitProps[]) => void;
      onBatchSubmitted?: (batches: BatchSubmitProps[]) => void;
    }
  | {
      multiselect?: false;
      onVersionSubmitted?: (version: VersionSubmitProps) => void;
      onEnvironmentSubmitted?: (environments: EnvironmentSubmitProps) => void;
      onBatchSubmitted?: (batch: BatchSubmitProps) => void;
    }
) &
  (
    | {
        /**
         * The type of picker to show.
         *
         * If "file", the user will be able to select a file first, then a version. If
         * "version", the user will be able to select a version of a file directly.
         */
        pickerType?: "file";
        initialVersion?: File | null;
        status?: "committed" | "uncommitted" | null;
      }
    | {
        /**
         * If "version", the user will be able to select a version of a file directly.
         * The `file` prop is required in this case.
         */
        pickerType: "version";
        file: File;
        initialVersion?: File | null;
        status?: "committed" | "uncommitted" | null;
      }
    | {
        /**
         * If "batch", the user will be able to select a batch.
         * The `file` prop is required in this case.
         */
        pickerType: "batch";
        file: File;
        versionProps?: { version: File | null; datasetVersion: Dataset | null };
      }
  );

/**
 * Picker to select a version of any file in the org.
 */
export const FilePicker = (props: FilePickerProps) => {
  // Which file has been selected in the first step. If the user has not yet
  // selected a file, this will be null. The nullness of this state determines
  // which view should be shown.
  const initialSelectedFile = props.pickerType === "version" || props.pickerType === "batch" ? props.file : null;
  const [selectedFile, setSelectedFile] = useState<File | null>(initialSelectedFile);

  const { structure } = useDirectoryStructure();
  const rootDirectory = structure?.directories.find((directory) => directory.parent_id === null);

  // The file context we are in. If we are not in a file context, this will be null.
  const fileContext = useMonitoringFile({ allowUndefined: true });

  // Which directory to start the picking process from. In order of precedence:
  // - The user specified directory
  // - The directory of the file we are currently in
  // - The root directory of the org
  const initialDirectoryId = props.initialDirectoryId || fileContext?.file?.directory_id || rootDirectory?.id || null;
  const [directoryId, setDirectoryId] = useState<string | null>(initialDirectoryId);
  const { directory } = useDirectory(directoryId, { keepPreviousData: true });

  const handleNavigate = useCallback(
    (directory: Directory) => {
      setDirectoryId(directory.id);
    },
    [setDirectoryId],
  );

  const fileTypeFilterText =
    props.fileTypeFilter && !Array.isArray(props.fileTypeFilter) ? capitalize(props.fileTypeFilter) : "File";
  const title = props.title
    ? props.title
    : props.fileTypeFilter === "evaluator"
      ? `Choose an ${fileTypeFilterText}`
      : `Choose a ${fileTypeFilterText}`;

  const handleClose = () => {
    props.onClose();
    if (props.resetOnClose) {
      setDirectoryId(initialDirectoryId);
      setSelectedFile(initialSelectedFile);
    }
  };

  const handleOnSubmit = useCallback(
    (data: EntityOnSubmitProps[]) => {
      const versionItems = data.filter((item) => "version" in item);
      if (props.onVersionSubmitted && versionItems.length > 0) {
        if (props.multiselect) {
          props.onVersionSubmitted(versionItems as VersionSubmitProps[]);
        } else {
          props.onVersionSubmitted(versionItems[0] as VersionSubmitProps);
        }
      }
      const environmentItems = data.filter((item) => "environment" in item);
      if (props.onEnvironmentSubmitted && environmentItems.length > 0) {
        if (props.multiselect) {
          props.onEnvironmentSubmitted(environmentItems as EnvironmentSubmitProps[]);
        } else {
          props.onEnvironmentSubmitted(environmentItems[0] as EnvironmentSubmitProps);
        }
      }
      const batchItems = data.filter((item) => "batch" in item);
      if (props.onBatchSubmitted && batchItems.length > 0) {
        if (props.multiselect) {
          props.onBatchSubmitted(batchItems as BatchSubmitProps[]);
        } else {
          props.onBatchSubmitted(batchItems[0] as BatchSubmitProps);
        }
      }
    },
    [props],
  );

  const previousOpen = usePrevious(props.open);
  useEffect(() => {
    if (!props.open && previousOpen) {
      if (props.resetOnClose) {
        setDirectoryId(initialDirectoryId);
        setSelectedFile(initialSelectedFile);
      }
    }
  }, [props.open, previousOpen, props.resetOnClose, initialDirectoryId, initialSelectedFile]);

  return (
    <RadixAppDialog
      open={props.open}
      onClose={handleClose}
      title={title}
      description={
        props.description && (
          <div className="text-text-base-4">
            <p>{props.description}</p>
          </div>
        )
      }
      className="h-[488px] text-12-14"
      width="wide"
    >
      {selectedFile ? (
        props.pickerType === "batch" ? (
          <BatchPickerStep
            multiselect={!!props.multiselect}
            file={selectedFile}
            onNavigateDirectory={(dir) => {
              setSelectedFile(null);
              handleNavigate(dir);
            }}
            disabledIds={props.disabledIds}
            onSubmitted={handleOnSubmit}
            submitButton={props.submitButton}
            onClose={handleClose}
            navigateDisabled={props.navigateDisabled}
            showEnvironments={false}
            versionProps={props.versionProps}
          />
        ) : (
          <VersionPickerStep
            multiselect={!!props.multiselect}
            file={selectedFile}
            onNavigateDirectory={(dir) => {
              setSelectedFile(null);
              handleNavigate(dir);
            }}
            initialItems={
              props.initialVersion
                ? [{ type: "version", version: props.initialVersion, id: props.initialVersion.version_id }]
                : []
            }
            disabledIds={props.disabledIds}
            status={props.status}
            onSubmitted={handleOnSubmit}
            submitButton={props.submitButton}
            onClose={handleClose}
            navigateDisabled={props.navigateDisabled}
            showEnvironments={props.showEnvironments}
          />
        )
      ) : (
        <FilePickerStep
          directory={directory}
          fileTypeFilter={props.fileTypeFilter}
          onNavigate={handleNavigate}
          onFileSelected={(file) => {
            props.onFileSelected && props.onFileSelected(file);
            return setSelectedFile(file);
          }}
          fileIdsToExclude={props.fileIdsToExclude}
        />
      )}
    </RadixAppDialog>
  );
};

interface FilePickerStepProps {
  directory?: DirectoryWithParentsAndChildren;
  fileTypeFilter?: File["type"] | File["type"][];
  fileIdsToExclude?: string[];
  onNavigate?: (directory: Directory) => void;
  onFileSelected?: (file: File) => void;
}

/** Select a File */
const FilePickerStep = (props: FilePickerStepProps) => {
  const { directory, onNavigate } = props;

  const [search, setSearch] = useState("");
  const isSearching = search.length > 0;

  const [sortState, setSortState] = useState<SortState>(null);

  const directoryIsEmpty = directory?.subdirectories.length === 0 && directory?.files.length === 0;

  const subdirectories = directory
    ? (search === undefined
        ? directory.subdirectories
        : directory.subdirectories.filter((subdirectory) =>
            subdirectory.name.toLowerCase().includes(search.toLowerCase()),
          )
      ).map(
        (d) =>
          ({
            type: "directory",
            value: d,
          }) as const,
      )
    : [];

  const files = directory
    ? (search === undefined
        ? directory.files
        : directory.files.filter((file) => file.name.toLowerCase().includes(search.toLowerCase()))
      )
        .filter(
          (file) =>
            !props.fileTypeFilter ||
            (Array.isArray(props.fileTypeFilter)
              ? props.fileTypeFilter.includes(file.type)
              : file.type === props.fileTypeFilter),
        )
        .filter((file) => !props.fileIdsToExclude || !props.fileIdsToExclude.includes(file.id))
        .map(
          (f) =>
            ({
              type: "file",
              value: f,
            }) as const,
        )
    : [];

  const items = [...subdirectories, ...files];
  const sortedItems = sortState ? sortItems(items, sortState) : items;

  return (
    <div className="flex h-[366px] flex-col">
      {/* Search */}
      <Search value={search} setValue={setSearch} placeholder="Search" />

      {/* Breadcrumbs */}
      {!isSearching && directory && (
        <DirectoryBreadcrumbs
          className="-ml-12"
          directory={directory}
          onClick={(directory) => onNavigate && onNavigate(directory)}
          renderRoot
        />
      )}

      {/* Divider */}
      <hr className="-mx-32 mt-8 flex items-center border-stroke-base-2 px-32 pb-8" />

      <div className="flex min-h-0 grow flex-col">
        {/* File List */}
        <div className="-mx-32 flex h-[360px] min-h-0 grow flex-col overflow-hidden">
          {/* Headings */}
          {!directoryIsEmpty && items.length > 0 && (
            <div className="flex">
              {/* Name */}
              <ColumnHeading
                heading="Name"
                sort={sortState && sortState.column === "name" ? sortState.direction : null}
                setSort={(direction) => setSortState(direction && { column: "name", direction })}
              />

              {/* Updated at */}
              <ColumnHeading
                heading="Updated At"
                sort={sortState && sortState.column === "updated_at" ? sortState.direction : null}
                setSort={(direction) => setSortState(direction && { column: "updated_at", direction })}
              />
            </div>
          )}

          <div className="h-full min-h-0">
            <ScrollArea.Root className="h-full">
              <ScrollArea.Viewport className="h-full [&>div]:!block [&>div]:!h-full">
                {directoryIsEmpty && (
                  <div className="flex h-full items-center justify-center text-13-14 font-light text-text-base-3">
                    This directory is empty
                  </div>
                )}
                {items.length === 0 && (
                  <div className="flex h-full items-center justify-center text-13-14 font-light text-text-base-3">
                    No results
                  </div>
                )}
                {!directoryIsEmpty && directory && (
                  <FilesList items={sortedItems} onFileSelected={props.onFileSelected} onNavigate={onNavigate} />
                )}
              </ScrollArea.Viewport>

              <ScrollArea.Scrollbar
                orientation="vertical"
                className="z-50 flex w-[7px] overflow-hidden rounded bg-gray-100 p-2"
              >
                <ScrollArea.Thumb className="flex-auto rounded bg-gray-200" />
              </ScrollArea.Scrollbar>
            </ScrollArea.Root>
          </div>
        </div>

        {/* Buttons */}
        {/* Search-state Breadcrumbs */}
        {isSearching && directory && (
          <div className="-mx-32 -mb-[30px] flex h-44 items-center border-t border-stroke-base-2 px-32 py-6">
            <DirectoryBreadcrumbs
              className="-ml-12"
              breadcrumbStyle="normal"
              directory={directory}
              onClick={(directory) => onNavigate && onNavigate(directory)}
              renderRoot
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface DirectoriesListProps {
  items: ({ type: "directory"; value: Directory } | { type: "file"; value: File })[];
  onNavigate?: (directory: Directory) => void;
  onFileSelected?: (file: File) => void;
}

/**
 * Renders the subdirectories and files contained in a directory.
 */
const FilesList = ({ items, onFileSelected, onNavigate }: DirectoriesListProps) => {
  return (
    <div className="px-16 pb-32 pt-2">
      <div className="flex flex-col gap-2">
        {items.map((item) =>
          item.type === "directory" ? (
            <DirectoryListItem
              key={item.value.id}
              directory={item.value}
              onNavigate={() => {
                onNavigate && onNavigate(item.value);
              }}
            />
          ) : (
            <FileListItem
              key={item.value.id}
              file={item.value}
              selected={false}
              // selected={selectedDirectory?.id === item.value.id}
              onSelect={() => onFileSelected && onFileSelected(item.value)}
              onNavigate={() => {}}
            />
          ),
        )}
      </div>
    </div>
  );
};

interface DirectoriesListItemProps {
  directory: Directory;
  onNavigate: () => void;
}

const DirectoryListItem = ({ directory, onNavigate }: DirectoriesListItemProps) => (
  <button
    className="group/directory flex h-40 items-center justify-between gap-12 truncate rounded-ms px-16 hover:shadow-card-md"
    onClick={onNavigate}
  >
    <div className="flex min-w-0 items-center gap-12">
      <FolderSimple size={16} weight="fill" className="shrink-0 text-icon-base-3" />
      <div className="w-[240px] truncate text-left text-13-20 font-medium text-gray-950">{directory.name}</div>
      <div className="truncate text-13-20 font-light text-text-base-2">
        <DynamicTimestamp timestamp={directory.updated_at} />
      </div>
    </div>
    <div className="flex items-center">
      <ChevronRightIcon className="h-14 w-14 text-gray-950" onClick={onNavigate} />
    </div>
  </button>
);

interface FileListItemProps {
  file: File;
  selected: boolean;
  onSelect: () => void;
  onNavigate: () => void;
}

const FileListItem = ({ file, selected, onSelect, onNavigate }: FileListItemProps) => (
  <button
    className={classNames(
      "group/directory flex h-40 items-center justify-between gap-12 truncate rounded-ms px-16",
      selected ? "bg-blue-50 hover:shadow-card-cta" : "hover:shadow-card-md",
    )}
    onClick={onSelect}
    onDoubleClick={onNavigate}
  >
    <div className="flex min-w-0 items-center gap-12">
      <EntityIcon type={file.type} size={16} />
      <div className="w-[240px] truncate text-left text-13-14 font-medium text-gray-950">{file.name}</div>
      <div className="truncate text-13-20 font-light text-text-base-2">
        <DynamicTimestamp timestamp={file.updated_at} />
      </div>
    </div>
    <div className="flex items-center">
      <ChevronRightIcon className="h-14 w-14 text-gray-950" onClick={onNavigate} />
    </div>
  </button>
);

interface Props {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  loading?: boolean;
}

export const Search = ({ value, setValue, placeholder, loading }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Ensure the input is focused when the dialog opens
    inputRef.current?.focus();
  });

  useEffect(() => {
    // Focus the input when the user presses Cmd+F
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "f") {
        e.preventDefault();
        inputRef.current?.focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.key === "Escape") {
      if (value) {
        setValue("");
      } else {
        inputRef.current?.blur();
      }
    }
  };

  return (
    <div className="flex w-full grow items-center gap-12">
      <div
        className={classNames(
          "group relative flex grow items-center rounded pb-4 text-gray-700 outline-none transition-all focus:outline-none",
        )}
      >
        <MagnifyingGlass className="absolute h-[14px] w-[14px] opacity-60 group-focus-within:opacity-100" />
        <input
          ref={inputRef}
          className="h-32 w-full rounded px-12 pl-28 text-12-12 text-gray-600 placeholder-gray-500 focus:text-oldgray-900 focus:outline-none"
          placeholder={placeholder}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyDown={handleKeyDown}
          value={value}
          autoFocus
        />
        {loading && <LoadingIcon className="absolute right-12 h-20 w-20 opacity-60" />}
      </div>
    </div>
  );
};

interface ColumnHeadingProps {
  heading: string;
  sort: "asc" | "desc" | null;
  setSort?: (sort: "asc" | "desc" | null) => void;
}

const ColumnHeading = ({ heading, sort, setSort }: ColumnHeadingProps) => {
  const handleSort = () => {
    if (!setSort) return;

    if (sort === "asc") {
      setSort("desc");
    } else if (sort === "desc") {
      setSort(null);
    } else {
      setSort("asc");
    }
  };

  const Icon = sort === "asc" ? ArrowUp : sort === "desc" ? ArrowDown : null;

  return (
    <div
      className="flex h-40 w-[280px] cursor-pointer items-center justify-between pb-8 pl-32 pt-12"
      onClick={handleSort}
    >
      <button className="flex items-center gap-x-4 p-2 text-text-base-4 hover:text-text-base-3">
        <div className="select-none text-10-10 font-medium uppercase">{heading}</div>
        {Icon !== null && <Icon className="h-24 w-24 p-6" />}
      </button>
    </div>
  );
};

const sortItems = (items: ({ type: "directory"; value: Directory } | { type: "file"; value: File })[], sort: Sort) => {
  const { column, direction } = sort;
  const cloned = [...items];

  if (column === "name") {
    return cloned.sort((a, b) => {
      const aVal = a.value.name;
      const bVal = b.value.name;
      return direction === "asc" ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
    });
  } else {
    return cloned.sort((a, b) => {
      const aVal = a.value.updated_at;
      const bVal = b.value.updated_at;
      return direction === "asc" ? compareDates(aVal, bVal) : -compareDates(aVal, bVal);
    });
  }
};
