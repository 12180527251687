import { classNames } from "@/lib/utils";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React from "react";

// SplitTag in the design system.
// https://www.figma.com/file/Ya7mCOIZJp1UdBCH3VxnEL/Components-v2?type=design&node-id=30-243&mode=design&t=PDjkh9xVGyzulrie-11
// Currently all instances of this are buttons, but that's not strictly required.

interface OwnProps {
  // Would rather these be named `label` and `value` but they conflict with the button props.
  tagLabel: string;
  tagValue: React.ReactNode;
  // Whether the tag can be clicked etc - adds focus/hover effects
  interactive?: boolean;
  active?: boolean;
}

type Props = OwnProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
// Have to accept button props to allow it to be used as a child for things like radix's Popover.Trigger;

const SplitTag = (
  { tagLabel, tagValue, interactive, active, className, ...props }: React.PropsWithChildren<Props>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  return (
    <button
      ref={ref}
      {...props}
      className={classNames(
        "flex h-24  items-center overflow-hidden rounded-ms border border-stroke-base-3 text-text-base-1 ",
        active ? "shadow-focus-ring" : "",
        interactive ? "select-none hover:border-stroke-base-5 active:shadow-focus-ring" : "cursor-default",
        className,
      )}
    >
      <div className="flex h-full items-center bg-background-base-3 px-6">
        <div className="text-11-12">{tagLabel}</div>
      </div>

      <div className="flex h-full items-center bg-background-base-1 px-6">
        <div className="font-mono text-11-12 font-bold">{tagValue}</div>
        {/* Not added but i think this can be helpful to distinguish between clickable and static tags */}
        {/* <ChevronDownIcon className="ml-4 h-12 w-12 text-text-base-2" /> */}
      </div>
    </button>
  );
};

export default React.forwardRef(SplitTag);
