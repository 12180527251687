import curl from "highlightjs-curl";
import { Light as SyntaxHighlighter, SyntaxHighlighterProps } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/cjs/languages/hljs/bash";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import markdown from "react-syntax-highlighter/dist/cjs/languages/hljs/markdown";
import python from "react-syntax-highlighter/dist/cjs/languages/hljs/python";
import typescript from "react-syntax-highlighter/dist/cjs/languages/hljs/typescript";
import lightTheme from "react-syntax-highlighter/dist/cjs/styles/hljs/atom-one-light";

SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("typescript", typescript);
SyntaxHighlighter.registerLanguage("javascript", typescript); // Using typescript styles here as they seem identical.
SyntaxHighlighter.registerLanguage("curl", curl);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("markdown", markdown);

interface Props extends SyntaxHighlighterProps {
  language: "python" | "json" | "typescript" | "javascript" | "curl" | "bash" | "markdown";
  noPadding?: boolean;
}

const CodePreview = ({ language, noPadding, ...props }: Props) => {
  return (
    <SyntaxHighlighter
      {...props}
      language={language}
      style={lightTheme}
      customStyle={{
        backgroundColor: "transparent",
        paddingLeft: noPadding ? undefined : 8,
        paddingRight: noPadding ? undefined : 8,
        paddingBlockStart: noPadding ? 0 : undefined,
        paddingBlockEnd: noPadding ? 0 : undefined,
        lineHeight: 1.5,
        ...props.customStyle,
      }}
    >
      {props.children}
    </SyntaxHighlighter>
  );
};

export default CodePreview;
