import Markdown from "markdown-to-jsx";
import React from "react";

/**
 * Wrapper around markdown-to-jsx that disables parsing raw HTML.
 * This prevents HTML injection attacks.
 */
export const SafeMarkdown = ({ options, ...props }: React.ComponentProps<typeof Markdown>) => {
  return <Markdown options={{ disableParsingRawHTML: true, ...options }} {...props} />;
};
