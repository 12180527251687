import { ConditionalWrapper } from "@/lib/utils";
import IconButton, { IconButtonProps } from "@components/library/IconButton";
import { ArrowRightIcon, ArrowUpRightIcon } from "@components/library/Icons";
import { ConditionalTooltip } from "@components/library/Tooltip";
import React from "react";

/** Common Icon Button that links with an arrow to a URL
 *
 * ArrowRight if same tab
 * ArrowUpRight if new tab
 *
 */
export const IconButtonLink = ({
  href,
  target = "_self",
  shade = "gray",
  tooltip,
  ...props
}: Omit<IconButtonProps, "Icon"> & {
  tooltip?: React.ReactNode;
}) => {
  return (
    <ConditionalTooltip condition={!!tooltip} content={tooltip}>
      <IconButton
        className="shrink-0"
        shade={shade}
        {...props}
        href={href}
        target={target}
        Icon={target === "_blank" ? ArrowUpRightIcon : ArrowRightIcon}
      />
    </ConditionalTooltip>
  );
};
